import {
    CREATE_JOB,
    CREATE_JOB_ERROR,
    CREATE_JOB_SUCCESS,
    START_JOB,
    START_JOB_ERROR,
    START_JOB_SUCCESS,
    DELETE_JOB,
    DELETE_JOB_SUCCESS,
    DELETE_JOB_ERROR,
    GET_INACTIVE_JOBS,
    GET_INACTIVE_JOBS_SUCCESS,
    GET_INACTIVE_JOBS_ERROR,
    RESTORE_JOBS,
    RESTORE_JOBS_SUCCESS,
    RESTORE_JOBS_ERROR,
    DISPATCH_FILE,
    HANDLE_SUBMIT_FILE_UPLOAD_LOADER,
    IMAGE_UPLOAD_PROGRESS_LISTEN,
    SUBMIT_FILE_UPLOAD_LISTEN,
    SUBMIT_FILE_UPLOAD_SUCCESS,
    GET_COMPLETED_JOBS,
    GET_COMPLETED_JOBS_SUCCESS,
    DELETE_PERM_JOB,
    DELETE_PERM_JOB_SUCCESS,
    SET_SELECTED_JOB_ID,
    FETCH_SELECTED_JOB,
    FETCH_SELECTED_JOB_SUCCESS,
    FETCH_SELECTED_JOB_ERROR,
    START_GENERATOR_PROCESS,
    START_GENERATOR_PROCESS_SUCCESS,
    START_GENERATOR_PROCESS_ERROR,
    UPLOAD_NEW_INPUT,
    UPLOAD_NEW_INPUT_SUCCESS,
    UPLOAD_NEW_INPUT_ERROR,
    CLEAR_CSV,
    GO_TO_RESULTS,
    CLEAR_GO_TO_RESULTS,
    GET_COMPLETED_JOBS_ERROR,
    LOG_FAILED_GENERATOR_SUBMISSION
} from "./constants";

export function createJob(data, dispatch, id) {
    return {
        type: CREATE_JOB,
        payload: data,
        dispatch,
        id
    }
}

export function createJobSuccess(jobId, targets, input) {
    return {
        type: CREATE_JOB_SUCCESS,
        payload: jobId,
        targets,
        input
    }
}

export function createJobError() {
    return {
        type: CREATE_JOB_ERROR,
    }
}

export function startJob(id, seqName, inputId, targets) {
    return {
        type: START_JOB,
        id,
        seqName,
        inputId,
        targets
    }
}

export function startJobSuccess() {
    return {
        type: START_JOB_SUCCESS
    }
}

export function startJobError() {
    return {
        type: START_JOB_ERROR
    }
}

export function deleteJob(id, method, page) {
    return {
        type: DELETE_JOB,
        id,
        method,
        page
    }
}

export function deleteJobSuccess() {
    return {
        type: DELETE_JOB_SUCCESS
    }
}

export function deleteJobError() {
    return {
        type: DELETE_JOB_ERROR
    }
}

export function deleteJobPerm(id) {
    return {
        type: DELETE_PERM_JOB,
        id
    }
}

export function deleteJobPermSuccess() {
    return {
        type: DELETE_PERM_JOB_SUCCESS
    }
}

export function deleteJobPermError() {
    return {
        type: DELETE_JOB_ERROR
    }
}

export function getInactiveJobs() {
    return {
        type: GET_INACTIVE_JOBS,
    }
}

export function getInactiveJobsSuccess(data) {
    return {
        type: GET_INACTIVE_JOBS_SUCCESS,
        payload: data
    }
}

export function getInactiveJobsError() {
    return {
        type: GET_INACTIVE_JOBS_ERROR,
    }
}

export function getCompletedJobs() {
    return {
        type: GET_COMPLETED_JOBS,
    }
}

export function getCompletedJobsSuccess(data) {
    return {
        type: GET_COMPLETED_JOBS_SUCCESS,
        payload: data
    }
}

export function getCompletedJobsError() {
    return {
        type: GET_COMPLETED_JOBS_ERROR,
    }
}

export function restoreJobs(jobId) {
    return {
        type: RESTORE_JOBS,
        jobId
    }
}

export function restoreJobsSuccess() {
    return {
        type: RESTORE_JOBS_SUCCESS
    }
}

export function restoreJobsError() {
    return {
        type: RESTORE_JOBS_ERROR,
    }
}

//Use this to dispatch the file to the reducer
export const dispatchFileToTheReducerAction = (file) => {

    return {
        type: DISPATCH_FILE,
        payload: file
    }
}

//Image upload progress
export const imageUploadProgress = (progress) => {
    return {
        type: IMAGE_UPLOAD_PROGRESS_LISTEN,
        payload: progress
    }
}

//Use this to submit the job file
export const handleUploadFileSubmitListen = (id, history) => {
    return {
        type: SUBMIT_FILE_UPLOAD_LISTEN,
        payload: id,
        history
    }
}

export const handleUploadFileSubmitSuccess = () => {
    return {
        type: SUBMIT_FILE_UPLOAD_SUCCESS,
    }
}

export const handleUploadFileSubmitLoader = (state) => {
    return {
        type: HANDLE_SUBMIT_FILE_UPLOAD_LOADER,
        payload: state
    }
}

export const setSelectedJobId = (jobId) => {
    return {
        type: SET_SELECTED_JOB_ID,
        jobId
    }
}

export const fetchSelectedJobSummary = (jobId) => {
    return {
        type: FETCH_SELECTED_JOB,
        jobId
    }
}

export const fetchSelectedJobSummarySuccess = (data) => {
    return {
        type: FETCH_SELECTED_JOB_SUCCESS,
        data
    }
}

export const fetchSelectedJobSummaryError = () => {
    return {
        type: FETCH_SELECTED_JOB_ERROR
    }
}

export const startGeneratorProcess = (data) => {
    return {
        type: START_GENERATOR_PROCESS,
        data
    }
}

export const startGeneratorProcessSuccess = () => {
    return {
        type: START_GENERATOR_PROCESS_SUCCESS
    }
}

export const startGeneratorProcessError = () => {
    return {
        type: START_GENERATOR_PROCESS_ERROR
    }
}

export const logFailedGeneratorSubmission = (data) => {
    return {
        type: LOG_FAILED_GENERATOR_SUBMISSION,
        data
    }
}

export function uploadNewInput(data, dispatch, id) {
    return {
        type: UPLOAD_NEW_INPUT,
        payload: data,
        dispatch,
        id
    }
}

export function uploadNewInputSuccess() {
    return {
        type: UPLOAD_NEW_INPUT_SUCCESS
    }
}

export function uploadNewInputError() {
    return {
        type: UPLOAD_NEW_INPUT_ERROR,
    }
}

export function clearCSV() {
    return {
        type: CLEAR_CSV,
    }
}

export function goToRsults() {
    return {
        type: GO_TO_RESULTS,
    }
}

export function clearGoToRsults() {
    return {
        type: CLEAR_GO_TO_RESULTS,
    }
}