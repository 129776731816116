import MenuBar from "../../components/menuBar";
import SideDrawCustom from "../../components/SideDrawCustom";
import { Button, TextField, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateUserProfileListen } from "../../redux/users/actions";

const UserView = () => {
  const dispatch = useDispatch();

  const { user, updateProfileLoading, userDataLoading } = useSelector(
    (state) => state.userReducer
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [labDeliveryAddress, setLabDeliveryAddress] = useState("");

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setInstitution(user.institution);
    setBillingAddress(user.billing_address);
    setLabDeliveryAddress(user.lab_delivery_address);
  }, [user]);

  const updateUserProfile = () => {
    dispatch(updateUserProfileListen({ firstName, lastName, institution, billingAddress, labDeliveryAddress}));
  };
  if (userDataLoading) {
    return (
      <div>
        <CircularProgress
          className="mr-2"
          size={20}
          style={{ color: "green" }}
        />
      </div>
    );
  }

  return (
    <div style={{overflow:"scroll" }}>
      <MenuBar />
      <div className="d-flex" >
        <SideDrawCustom activeRoute={2} />
        <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
          <p className="text-semi-medium">User</p>
          <div className="mt-3 green-border p-3 d-flex flex-column">
            <div className="mb-3">
              <p className="text-grey text-semi-bold">User id: {user.id}</p>
            </div>
            <div>
              <div className="d-flex justify-between">
                <div className="mb-4" style={{ width: "48%" }}>
                  <TextField
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    id="outlined-basic"
                    label="First Name*"
                    variant="outlined"
                    className="w-100"
                  />
                </div>
                <div className="w-50" style={{ width: "48%" }}>
                  <TextField
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    id="outlined-basic"
                    label="Last Name*"
                    variant="outlined"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="mb-4" style={{ width: "48%" }}>
                <TextField
                  disabled
                  value={email}
                  id="outlined-basic"
                  label="Email Address*"
                  variant="outlined"
                  className="w-100"
                />
              </div>
              <div className="mb-4" style={{ width: "48%" }}>
                <TextField
                  onChange={(e) => setInstitution(e.target.value)}
                  value={institution}
                  id="outlined-basic"
                  label="Company/University Name*"
                  variant="outlined"
                  className="w-100"
                />
              </div>
              <div className="d-flex justify-between">
                <div className="mb-4" style={{ width: "48%" }}>
                  <TextField
                    onChange={(e) => setBillingAddress(e.target.value)}
                    value={billingAddress}
                    rows={2}
                    multiline
                    id="filled-multiline-static"
                    label="Billing Address*"
                    variant="outlined"
                    className="w-100"
                  />
                </div>
                <div className="w-50" style={{ width: "48%" }}>
                  <TextField
                    onChange={(e) => setLabDeliveryAddress(e.target.value)}
                    value={labDeliveryAddress}
                    rows={2}
                    multiline
                    id="filled-multiline-static"
                    label="Lab Delivery Address*"
                    variant="outlined"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-end" style={{ marginTop: "0px" }}>
              <Button
                onClick={() => updateUserProfile()}
                sx={{
                  "&.MuiButton-text": { color: "white" },
                  background: "#339604",
                  marginLeft: "30px",
                  ":hover": {
                    bgcolor: "#338d00", // theme.palette.primary.main
                    color: "white",
                  },
                }}
              >
                {updateProfileLoading && (
                  <CircularProgress
                    className="mr-2"
                    size={20}
                    style={{ color: "white" }}
                  />
                )}
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
