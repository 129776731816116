import * as actionTypes from "./constants"

//profile image upload actions
export const profileImageUploadListen = (file) => {

    return {
        type: actionTypes.PROFILE_IMAGE_UP_LISTEN,
        file
    }
}

export const profileImageUpdateSuccess = () => {

    return {
        type: actionTypes.PROFILE_IMAGE_UP_SUCCESS
    }
}

//Use this to get the current user
export const getCurUserListen = () => {

    return {
        type: actionTypes.GET_CUR_USER_LISTEN,
    }
}

export const getCurUserSuccess = (user) => {

    return {
        type: actionTypes.GET_CUR_USER_SUCCESS,
        payload: user
    }
}

export const getCurUserError = () => {

    return {
        type: actionTypes.GET_CUR_USER_ERROR,
    }
}


//Use this to update the user profile
export const updateUserProfileListen = (data) => {
    return {
        type: actionTypes.UPDATE_PROFILE_LISTEN,
        payload: data
    }
}

export const updateUserProfileSuccess = () => {

    return {
        type: actionTypes.UPDATE_PROFILE_SUCCESS
    }
}

export const updateUserProfileError = () => {

    return {
        type: actionTypes.UPDATE_PROFILE_ERROR
    }
}