import { 
    GET_PACKAGE_DATA, 
    GET_PACKAGE_DATA_SUCCESS, 
    GET_PACKAGE_DATA_ERROR, 
    SET_PACKAGE_DETAILS, 
    ADD_TO_CART, 
    ADD_TO_CART_SUCCESS, 
    ADD_TO_CART_ERROR ,
    GET_CART,
    GET_CART_ERROR,
    GET_CART_SUCCESS,
    UPDATE_CART,
    UPDATE_CART_ERROR,
    UPDATE_CART_SUCCESS,
    DELETE_CART,
    DELETE_CART_ERROR,
    DELETE_CART_SUCCESS,
    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_ERROR,
    GET_BALANCE,
    GET_BALANCE_SUCCESS,
    GET_BALANCE_ERROR
} from "./constants";

// ----------------------------------------- get package data --------------------------
export function getPackageData() {
    return {
        type: GET_PACKAGE_DATA
    }
}

export function getPackageDataSuccess(data) {
    return {
        type: GET_PACKAGE_DATA_SUCCESS,
        payload: data
    }
}

export function getPackageDataError() {
    return {
        type: GET_PACKAGE_DATA_ERROR
    }
}

// ----------------------------------------- set package data --------------------------

export function setPackageId(ipShareId, packageId) {
    return {
        type: SET_PACKAGE_DETAILS,
        ipShareId,
        packageId
    }
}

// ----------------------------------------- get cart data --------------------------

export function getCart() {
    return {
        type: GET_CART
    }
}

export function getCartSuccess(data) {
    return {
        type: GET_CART_SUCCESS,
        payload: data
    }
}

export function getCartError() {
    return {
        type: GET_CART_ERROR
    }
}

// ----------------------------------------- add to cart --------------------------

export function addToCart(suggestions, packageId) {
    return {
        type: ADD_TO_CART,
        suggestions,
        packageId
    }
}

export function addToCartSuccess() {
    return {
        type: ADD_TO_CART_SUCCESS,
    }
}

export function addToCartError() {
    return {
        type: ADD_TO_CART_ERROR
    }
}

// ----------------------------------------- delete from cart --------------------------

export function deleteFromCart(cartId) {
    return {
        type: DELETE_CART,
        cartId
    }
}

export function deleteFromCartSuccess() {
    return {
        type: DELETE_CART_SUCCESS,
    }
}

export function deleteFromCartError() {
    return {
        type: DELETE_CART_ERROR
    }
}

// ----------------------------------------- update cart --------------------------

export function updateCart(suggestions, pricePerSuggestion, cartId) {
    return {
        type: UPDATE_CART,
        suggestions,
        pricePerSuggestion,
        cartId
    }
}

export function updateCartSuccess() {
    return {
        type: UPDATE_CART_SUCCESS,
    }
}

export function updateCartError() {
    return {
        type: UPDATE_CART_ERROR
    }
}

// ----------------------------------------- create payment --------------------------

export function createPayment(items) {
    return {
        type: CREATE_PAYMENT,
        items
    }
}

export function createPaymentSuccess() {
    return {
        type: CREATE_PAYMENT_SUCCESS,
    }
}

export function createPaymentError() {
    return {
        type: CREATE_PAYMENT_ERROR
    }
}

// ----------------------------------------- get balance --------------------------

export function getCreditBalance() {
    return {
        type: GET_BALANCE
    }
}

export function getCreditBalanceSuccess(credits) {
    return {
        type: GET_BALANCE_SUCCESS,
        credits
    }
}

export function getCreditBalanceError() {
    return {
        type: GET_BALANCE_ERROR
    }
}