import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import userIcon from "../../assets/icons/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutListen } from "../../redux/login/actions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#339604",
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function MenuBar() {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const [itemCount, setItemCount] = useState('0');

  const {cartData} = useSelector(state => state.creditReducer);


  useEffect(() => {
    if (cartData) {
      setItemCount(cartData.length?.toString())
    }
  }, [cartData])

  const routeToUser = () => {
    history.push("/user");
  };
  const routeToCart = () => {
    history.push("/cart");
  };

  const logoutUser = () => {
    dispatch(logoutListen());
  };

  return (
    <AppBar
      className={classes.appBar}
      style={{ backgroundColor: "#339604" }}
      position="static"
    >
      <Toolbar variant="dense">
        <Typography variant="subtitle1" className={classes.title}>
          Exazyme Web
        </Typography>
          <div>
            <Button onClick={routeToCart} color="inherit">
              <ShoppingCartIcon width="50%" />
              <span
                className={`cart-count ${classes.countBackground}`}
                style={{
                  minWidth: "1.2rem",
                  height: "1.2rem",
                  marginLeft: "-7px",
                  marginTop: "-25px",
                  background: "black",
                  borderRadius: "50px",
                  color:"white",
                  fontWeight:"bold"
                }}
              >{itemCount}</span>
            </Button>
            <Button onClick={routeToUser} color="inherit">
              <img src={userIcon} width="50%" />
            </Button>
            <Button onClick={logoutUser} color="inherit">
              Logout
            </Button>
          </div>
      </Toolbar>
    </AppBar>
  );
}

export default MenuBar;
