import produce from "immer";
import { 
    GET_PACKAGE_DATA,
    GET_PACKAGE_DATA_SUCCESS,
    GET_PACKAGE_DATA_ERROR,
    SET_PACKAGE_DETAILS,
    ADD_TO_CART, 
    ADD_TO_CART_SUCCESS, 
    ADD_TO_CART_ERROR ,
    GET_CART,
    GET_CART_ERROR,
    GET_CART_SUCCESS,
    UPDATE_CART,
    UPDATE_CART_ERROR,
    UPDATE_CART_SUCCESS,
    DELETE_CART,
    DELETE_CART_ERROR,
    DELETE_CART_SUCCESS,
    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_ERROR,
    GET_BALANCE,
    GET_BALANCE_SUCCESS
 } from "./constants";


const INIT_STATE = {
    packageData: [],
    cartData: [],
    packageDataLoading: false,
    ipShareId: null,
    packageId: null,
    cartDataLoading: false,
    cartActionLoading: false,
    paymentProcessing: false,
    creditBalances: [],
    creditBalanceLoading: false
};

export default function creditReducer(state = INIT_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case GET_PACKAGE_DATA:
                draft.packageDataLoading = true;
                break;
            case GET_PACKAGE_DATA_SUCCESS:
                draft.packageDataLoading = false;
                draft.packageData = action.payload
                break;
            case GET_PACKAGE_DATA_ERROR:
                draft.packageDataLoading = false;
                break;
            case SET_PACKAGE_DETAILS:
                draft.ipShareId = action.ipShareId;
                draft.packageId = action.packageId;
                break;
            case GET_CART:
                draft.cartDataLoading = true;
                break;
            case GET_CART_SUCCESS:
                draft.cartDataLoading = false;
                draft.cartData = action.payload
                break;
            case ADD_TO_CART:
                draft.cartActionLoading = true;
                break;
            case ADD_TO_CART_ERROR:
                draft.cartActionLoading = false;
                break;
            case ADD_TO_CART_SUCCESS:
                draft.cartActionLoading = false;
                break;
            case UPDATE_CART:
                draft.cartActionLoading = true;
                break;
            case UPDATE_CART_ERROR:
                draft.cartActionLoading = false;
                break;
            case UPDATE_CART_SUCCESS:
                draft.cartActionLoading = false;
                break;      
            case DELETE_CART:
                draft.cartActionLoading = true;
                break;
            case DELETE_CART_ERROR:
                draft.cartActionLoading = false;
                break;
            case DELETE_CART_SUCCESS:
                draft.cartActionLoading = false;
                break;    
            case CREATE_PAYMENT:
                draft.paymentProcessing = true;
                break;
            case CREATE_PAYMENT_SUCCESS:
                draft.paymentProcessing = false;
                break;
            case CREATE_PAYMENT_ERROR:
                draft.paymentProcessing = false;
                break;
            case GET_BALANCE:
                draft.creditBalanceLoading = true;
                break;
            case GET_BALANCE_SUCCESS:
                draft.creditBalanceLoading = false;
                draft.creditBalances = action.credits;
                break;
            case GET_CART_ERROR:
                draft.creditBalanceLoading = false;
                draft.cartDataLoading = false;
                break;
            default:
                break;
        }
    })
}