import MenuBar from "../../components/menuBar";
import SideDrawCustom from "../../components/SideDrawCustom";
import "../../assets/css/createJob.css";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  LinearProgress,
  Typography,
  // FormControl,
  // MenuItem,
  // Select
} from "@mui/material";
import file from "../../assets/icons/file.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  createJob,
  dispatchFileToTheReducerAction,
  startJob,
} from "../../redux/job/actions";
import { PROGRESS, COMPLETED, ERROR } from "../../redux/job/consts";
import { useEffect, useState, useCallback } from "react";
import DropDown from "./DropDown";
import {useHistory} from "react-router-dom";
import {useDropzone} from 'react-dropzone';
import { clearCSV } from "../../redux/job/actions";

const CreateJobView = () => {
  /* eslint-disable no-undef */
  const pathname = window.location.pathname;
  const id = pathname.split("/jobs/create/")[1];
  const history = useHistory();

  const { csvFile, progress, status, jobID, targetFields, inputId, startJobLoading, goToResults } =
    useSelector((state) => state.jobReducer);

  useEffect(() => {
    if (goToResults) history.push('/jobs/results')
  }, [goToResults])

  useEffect(()=>{
    dispatch(clearCSV())
  },[])

  // const { creditBalances } = useSelector((state => state.creditReducer));
  const [seqName, setSeqName] = useState("");
  const [targets, setTargets] = useState({});
  // const [ipShare, setIpShare] = useState(undefined);

  const dispatch = useDispatch();

  const dispatchFileToTheReducer = (file) => {
    dispatch(dispatchFileToTheReducerAction(file));
    dispatch(createJob(file, dispatch, id));
  };

  // useEffect(() => {
  //   if(creditBalances.length) setIpShare(creditBalances[0].ip_share)
  // }, [creditBalances]);

  const handleTextColor = () => {
    if (status === PROGRESS) {
      return "text-grey";
    } else if (status === COMPLETED) {
      return "text-success";
    } else if (status === ERROR) return "text-danger";
    else {
      return "text-grey";
    }
  };

  const handleStatusMessage = () => {
    if (status === PROGRESS) {
      return `${(csvFile.size / 1024).toFixed(2)} kb`;
    } else if (status === COMPLETED) {
      return "File successfully uploaded";
    } else if (status === ERROR) return "Failed to upload";
  };

  const submitJob = (id, seqName, inputId) => {
    dispatch(startJob(id, seqName, inputId, targets));
  };

  const submitFile = () => {
    if (jobID !== null) submitJob(jobID, seqName, inputId);
    setSeqName("");
  };

  const handleChange = (target, value) => {
        const current = targets;
        targets[target] = value;
        setTargets(current);
  };

  const onDrop = useCallback(acceptedFiles => {
    dispatchFileToTheReducer(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div>
      <MenuBar />
      <div className="d-flex">
        <SideDrawCustom activeRoute={2} />
        <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
          <p className="text-semi-medium">Jobs / Create a New Job</p>
          <div
            className="w-100 overflow-auto green-border"
            style={{ height: "80%" }}
          >
            <div>
              <p className="text-semi-medium text-grey pl-3 pt-1">
                Supports : Excel, CSV
              </p>
            </div>
            
            {/**
             * Todo : Move this component to generator classes page
             */}
            {/* <div className="mb-2 pl-3 pt-1">
              <FormControl style={{ width: "20%" }}>
                <Select
                  value={ipShare}
                  onChange={(e) => setIpShare(e.target.value)}
                  width={200}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "3px solid #339604",
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "white",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "rgba(51, 150, 4, 0.12)",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "rgba(51, 150, 4, 0.12)",
                        },
                      },
                    },
                  }}
                >
                  {creditBalances && creditBalances.map(el => 
                    <MenuItem key={el.ip_share} value={el.ip_share}>{el.ip_share}% IP share</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div> */}

            <div className="pl-3 pt-1 mb-2" style={{ width: "48%" }}>
              <TextField
                onChange={(e) => setSeqName(e.target.value)}
                value={seqName}
                id="outlined-basic"
                label="Sequence Name*"
                variant="outlined"
                className="w-100"
              />
            </div>
            <div {...getRootProps()} className="w-100 d-center" style={{ height: "50%" }}>
              <div
                className="w-100 bg-dark m-3 upload-back d-center"
                style={{ height: "100%" }}
              >
                <div className="d-flex justify-between">
                  <input
                    {...getInputProps()}
                  />
                  <label
                    className="btn btn-outlined-success d-center clickable"
                    htmlFor="selectFile"
                  >
                    <CloudUploadIcon className="mr-1" /> {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag and drop some files here, or click to select files</p>
              }
                  </label>
                </div>
              </div>
            </div>

            {csvFile && (
              <div className="p-3 relative">
                <div className="shadow-sm w-100">
                  <div className="p-1 d-flex">
                    <div className="d-center mr-2">
                      <img src={file} />
                    </div>
                    <div className="">
                      <p className="m-0">{csvFile.name}</p>
                      <p
                        className={`text-small ${handleTextColor()} text-semi-bold m-0`}
                      >
                        {handleStatusMessage()}
                      </p>
                    </div>
                  </div>
                  <Box
                    className="pl-1 pr-1 pb-1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress variant="buffer" value={progress} />
                    </Box>
                    {status === COMPLETED && (
                      <>
                        <Box
                          hidden={status === COMPLETED}
                          className="absolute progress-count"
                          sx={{ minWidth: 35 }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                          >{`${progress}%`}</Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </div>
              </div>
            )}
            {targetFields && targetFields.map(el => ( // Todo : Input fields hidden
              <DropDown
                  hidden // Todo : for now
                  handleChange={(value) => handleChange(el, value)}
                  target={el}
                  key={el}
              />
            ))}
            <div className="p-3">
              <div className="d-flex justify-end">
                {csvFile && (
                  <Button
                    onClick={() => submitFile()}
                    disabled={seqName === ""}
                    sx={{
                      "&.MuiButton-text": { color: "white" },
                      background: "#339604",
                      marginLeft: "30px",
                      ":hover": {
                        bgcolor: "#338d00", 
                        color: "white",
                      },
                    }}
                  >
                    {startJobLoading && (
                      <CircularProgress
                        size={14}
                        className="mr-1"
                        color="inherit"
                      />
                    )}
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJobView;
