import { FormControl, Select, MenuItem, TextField } from "@mui/material";
import { useState } from "react";

const BaseSeq = ({ value, data, handleChangeType, handleChangeText }) => {
  const [baseSeqType, setBaseSeqType] = useState(value);
  const [baseSeqText, setBaseSeqText] = useState(data ?? "");
  const handleValueChange = (value) => {
    setBaseSeqText(value);
    handleChangeText(value);
  };
  const handleTypeChange = (value) => {
    setBaseSeqType(value);
    handleChangeType(value);
  };

  return (
    <div className="pl-3 pt-1 d-flex" style={{ width: "60%" }}>
      <div className="mb-2" style={{ width: "48%" }}>
        <FormControl sx={{ minWidth: 220 }} size="small">
          <Select
            value={baseSeqType}
            onChange={(e) => handleTypeChange(e.target.value)}
            displayEmpty
          >
            <MenuItem value={"wildType"}>Wild type</MenuItem>
            <MenuItem value={"named"}>Named variant</MenuItem>
            <MenuItem value={"best"}>Best variant</MenuItem>
            <MenuItem value={"fixed"}>Fixed sequence</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="w-60" style={{ width: "48%" }}>
        {["named", "fixed"].includes(baseSeqType) && 
          <TextField
          onChange={(e) => handleValueChange(e.target.value)}
          value={baseSeqText}
          id="outlined-size-small"
          label="Text entry here"
          variant="outlined"
          size="small"
        />
        }
        
      </div>
    </div>
  );
};

export default BaseSeq;
