import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "../assets/css/dataTable.css";
import { useSelector } from "react-redux";

const CreditBalanceTable = () => {

  const {creditBalances} = useSelector(state => state.creditReducer);

  return (
    <div className="shadow-lg">
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{fontWeight:"bold"}}>MY IP SHARE</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>CREDIT BALANCE</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>USED</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditBalances && creditBalances.map((row) => {
              return (
                <TableRow className="table-row pointer" key={row?.id}>
                  <TableCell style={{ width: 160 }} align="center">
                    {row?.ip_share.toString()}%
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {row?.total - row?.used}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {row?.used}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CreditBalanceTable;
