import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  FormControl
} from "@mui/material";
import { useState } from "react";
import "../assets/css/dataTable.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cart from "../assets/icons/cart.png";
import { useEffect } from "react";
import { addToCart } from "../redux/credits/actions";

const AddCartTable = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [totalPrice, setTotalPrice] = useState(0);
  const [suggestions, setSuggestions] = useState(0);

  const {packageData, ipShareId, packageId} = useSelector(state => state.creditReducer);

  if (!(packageData && packageId && ipShareId)) {
    history.push("/credits/buy-credits/suggestion-credit");
  }

  const getMinPkg = (ipShare) => {
    let min = 0;
    for (const pkg of ipShare.packages) {
      if (pkg.noOfSuggestions !== null) {
        if (min === 0 || pkg.noOfSuggestions < min) {
          min = pkg.noOfSuggestions;
        }
      }
    }

    return min/2;
  }

  const ipShare = packageData.filter(el => el.ipShareId === ipShareId);
  const _package = ipShare[0]?.packages.filter(el => el.packageId === packageId)[0];

  getMinPkg(ipShare[0]);

  useEffect(() => {
    if (_package.noOfSuggestions !== null) {
      setTotalPrice(_package.noOfSuggestions * _package.pricePerSuggestion)
      setSuggestions(_package.noOfSuggestions)
    }
  }, [ipShare, _package])

  useEffect(() => {
    if (_package.noOfSuggestions === null) {
      setTotalPrice(suggestions * _package.pricePerSuggestion)
    }
  }, [suggestions])

  return (
    <div className="shadow-lg">
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight:"bold"}}>MY IP SHARE</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>PACKAGES</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>SUGGESTIONS</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>PRICE PER SUGGESTION</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>PACKAGE PRICE</TableCell>
              <TableCell align="right" style={{fontWeight:"bold"}}>ADD TO CART</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ipShare?.map((row) => {
              return (
                <TableRow className="table-row pointer" key={row?.id}>
                  <TableCell style={{ width: 160 }} align="left">
                    {row?.ipShare}%
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                  {_package?.noOfSuggestions || 'Custom'}
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="center">
                    <FormControl sx={{ m: 1, width: '15ch' }} variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        value={suggestions}
                        onChange={(e) => {
                          let val = parseInt(e.target.value, 10);
                          if (_package.noOfSuggestions === null) {
                            if (isNaN(val)) {
                              setSuggestions(0)
                            } else {
                              val = (val >= 0) ? (val > getMinPkg(ipShare[0])) ? getMinPkg(ipShare[0]) : val : 0;
                              setSuggestions(val)
                            }
                          }
                          
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                  {"€"}{_package?.pricePerSuggestion}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                  {"€"}{totalPrice}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    <Button
                      disableRipple
                      disableTouchRipple
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        height: 28,
                      }}
                      target="_blank"
                      disabled={suggestions === 0}
                      onClick={() => {
                        dispatch(addToCart(suggestions, _package.packageId));
                        history.push("/credits/buy-credits/checkout")
                       }
                      }
                    >
                      <img
                        src={cart}
                        width="25px"
                        className="mr-5 absolute google-icon"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AddCartTable;
