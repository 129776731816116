import { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import SeqType from "../generatorWidgets/SeqType";
import { TextField } from "@mui/material";

const FixedGenerator = ({ id, data, handleClose, handleChange }) => {
  const [GeneralParameters, setGeneralParameter] = useState(data.seqType ?? "aa");
  const [sequences, setSequneces] = useState(data.seqString ?? '');
  const [sequencesList, setSequenceList] = useState(data.sequences ?? []);
  const [sequenceFile, setSequneceFile] = useState(null);
  const [completed, setCompleted] = useState(false);

  const [sequencesError, setSequneceError] = useState('');

  function validateSequencesList(sequencesList, seqType) {
    if (!seqType) return false;
    for (const element of sequencesList) {
      let regex;
      if (seqType === 'aa') {
       regex = /^[ACDEFGHIKLMNPQRSTVWY]+$/;
      } 
      if (seqType === 'dna') {
        regex = /^[ACGT]+$/;
      }
      if (!regex.test(element)) {
        return false
      }
    }
    return true;
  }

  useEffect(() => {
    const data = {
      sequences: sequencesList,
      seqString: sequences,
      seqType: GeneralParameters,
      completed: (sequences.length> 0 && GeneralParameters !== "" && validateSequencesList(sequencesList, GeneralParameters))
    }
    setCompleted(sequences.length> 0 && GeneralParameters !== "" && validateSequencesList(sequencesList, GeneralParameters));
    handleChange(id, data);

    if(validateSequencesList(sequencesList, GeneralParameters)) {
      setSequneceError('')
    } else {
      setSequneceError('Invalid sequence')
    }

  }, [sequencesList, GeneralParameters]);

  useEffect(async () => {
    if (sequenceFile) {
      setSequneces(await sequenceFile.text())
    }
  }, [sequenceFile]);

  useEffect(() => {
    let items = sequences.split('\n').filter(el => !["\r", ""].includes(el));
    items = items.map(el => el.replace("\r", ""))
    setSequenceList(items);
  }, [sequences])

  const handleGeneralParaChange = (value) => {
    setGeneralParameter(value);
  };

  const handleCloseBtn = () => {
    handleClose(id);
  };

  const handleSequneceChange = (value) => {
    setSequneces(value);
  }

  return (
    <div className="w-80 gray-border" style={{ borderColor: completed ? 'grey' : "red" }}>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <CloseIcon
          style={{ color: "gray" }}
          className="clickable"
          onClick={() => {
            handleCloseBtn();
          }}
        />
      </div>
      <div className="pl-3" style={{ fontWeight: "500" }}>
        Use-Defined Sequences
      </div>
      <div>
        <SeqType key={id} seqType={GeneralParameters} handleChange={(value) => handleGeneralParaChange(value)} />
      </div>
      <div style={{ marginBottom: "30px"}} className="d-flex ">
        <div className="pl-3 pt-1 w-50" >
          <TextField
            onChange={(e) => handleSequneceChange(e.target.value)}
            value={sequences}
            error={sequencesError}
            rows={5}
            multiline
            id="filled-multiline-static"
            label="Sequences, one per line"
            variant="outlined"
            className="w-100"
          />
          <p className="text-small text-error p-1 m-0">{sequencesError}</p>
        </div>
        <div className="pl-3 pt-1">
          <input
            type="file"
            hidden
            id="selectFile"
            onChange={(e) => {
              setSequneceFile(e.target.files[0]);
            }}
          />
          <label
            className="btn btn-outlined-success d-center clickable"
            htmlFor="selectFile"
          >
            <CloudUploadIcon className="mr-1" /> Add sequences text file
          </label>
        </div>
      </div>
    </div>
  );
};

export default FixedGenerator;
