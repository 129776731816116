import * as actionTypes from "./constants"
import {call, put, takeLatest} from "redux-saga/effects";
import axios from "../../axios/axios";
import {getCurUserSuccess, updateUserProfileError, updateUserProfileSuccess, getCurUserListen, getCurUserError} from "./actions";
import { logoutListen } from "../login/actions";
import { Auth } from "aws-amplify";
import { fireAlertError, fireAlertSuccess } from "../../utils/baseUtils";

const profileUpdateAsync = async (file) => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;

    return axios.post("/image", {file}, {
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "multipart/form-data"
        }
    }).then(res => res).catch(err => {
        console.error(err.message)
    })
}

const updateUserProfileAsync = async (data) => {
    
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;

    return axios.patch("/users", data, {
        headers: {
            "Authorization": 'Bearer ' + token,
        }
    }).then(res => res).catch(err => {
        console.error(err.message)
    })
}

const getUserAsync = async () => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.get("/users", {
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "multipart/form-data"
        }
    }).then(res => res).catch(err => {
        console.error(err.message)
        throw err
    })
}

const registerUserGoogleSignUpAsync = async () => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.post("/users/google",{}, {
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "multipart/form-data"
        }
    }).then(res => res).catch(err => {
        throw err
    })
}

//////////////////
//ASYNC finished//
//////////////////

export function* profileUpdateCB(action) {
    const {file} = action
    try {
        yield call(profileUpdateAsync, file)
    }catch (err) {
        console.error(err.message)
    }
}

export function* getCurrentUserCB() {

    try {
        const res = yield call(getUserAsync)
        yield put(getCurUserSuccess(res.data))
    } catch (err) {
        yield put(getCurUserError())
        if (err.response.status === 404) {
            try {
                yield call(registerUserGoogleSignUpAsync)
                yield put(getCurUserListen());
            } catch (e) {
                yield put(logoutListen());
            }
        }
    }
}

export function* updateUserProfileCB(action) {

    const {payload} = action

    try {
        yield call(updateUserProfileAsync, payload);
        fireAlertSuccess("Success !", "Profile Updated !!!")
        yield put(updateUserProfileSuccess())
        yield put(getCurUserListen());
    } catch (err) {
        fireAlertError("Failed !")
        yield put(updateUserProfileError())
    }
}

export function* watchUserSagas() {
    yield takeLatest(actionTypes.PROFILE_IMAGE_UP_LISTEN, profileUpdateCB)
    yield takeLatest(actionTypes.GET_CUR_USER_LISTEN, getCurrentUserCB)
    yield takeLatest(actionTypes.UPDATE_PROFILE_LISTEN, updateUserProfileCB)
}

const userSagas = [watchUserSagas]

export default userSagas