import SideDrawCustom from "../../components/SideDrawCustom";
import MenuBar from "../../components/menuBar";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { clearGoToRsults, getCompletedJobs } from "../../redux/job/actions";
import JobTable from "../../components/JobTable";
import { INPROGRESS } from "../../constants";

const ResultView = () => {

    const dispatch = useDispatch()
    const [count, setCount] = useState(0);
    const {jobs} = useSelector(state => state.jobReducer);

    useEffect(() => {
        const timer = setTimeout(
          () =>{ 
            dispatch(getCompletedJobs()); 
            if (jobs) {
                const inProgressJobs = jobs.filter(el => el.status === INPROGRESS);
                if (inProgressJobs.length || count<2) {
                    setCount(count+1)}
                }
            },
          5000
        );
        return () => clearTimeout(timer);
    }, [count]);

    useEffect(() => {
        dispatch(getCompletedJobs());
        dispatch(clearGoToRsults());
    }, [])

    return <div>
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={1}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
                <p className="text-semi-medium">
                    Jobs / Results
                </p>
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <JobTable status={'results'}/>
                </div>
            </div>
        </div>
    </div>
}

export default ResultView