import * as actionTypes from "./constants"
import produce from "immer";

const INIT_STATE = {
    user: {},
    updateProfileLoading: false,
    userDataLoading: false
}

export default function userReducer(state = INIT_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
        case actionTypes.UPDATE_PROFILE_LISTEN:
            draft.updateProfileLoading = true;
            break;
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            draft.updateProfileLoading = false;
            break;
        case actionTypes.UPDATE_PROFILE_ERROR:
            draft.updateProfileLoading = false;
            break;
        case actionTypes.GET_CUR_USER_LISTEN:
            draft.userDataLoading = true;
            break;
        case actionTypes.GET_CUR_USER_SUCCESS:
            draft.user = action.payload;
            draft.userDataLoading = false;
            break;    
        default:
            return draft
        }
    })
}