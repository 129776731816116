import {
    call, put, takeEvery // select, take were removed
} from 'redux-saga/effects';
import {handleLoginLoader, handleSignupLoader, loginUserError, loginUserSuccess, logoutSuccess} from "./actions";
import {LOGIN_USER, LOGOUT_USER_LISTEN, SIGNIN_WITH_GOOGLE_LISTEN, SIGNUP_USER_LISTEN} from "./constants";
import {fireAlertError} from "../../utils/baseUtils";
import axios from "../../axios/axios";
// import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';


async function loginAsync({email, password}) {
    try {
        const user = await Auth.signIn(email, password);
        return user;
    } catch (error) {
        let code = error.message;
        if (code === 'User is not confirmed.') {
            code = "Confirm your account by clicking the confirmation link sent to your email"
        }
        fireAlertError("Account has not been confirmed", code)
    }
}

async function loginWithGoogleAsync() {
    const result = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    console.log(result)
    // const auth = getAuth();
    // const provider = getProvider()
    // return signInWithPopup(auth, provider)
    //     .then(async (result) => {
    //         GoogleAuthProvider.credentialFromResult(result);
    //         const token = await auth.currentUser.getIdToken();
    //         try {
    //             await axios.get("/users", {
    //                 headers: {
    //                     "Authorization": 'Bearer ' + token,
    //                     "Content-Type": "multipart/form-data"
    //                 }
    //             });
    //         } catch (error) {
    //             if (error.response.status === 404) {
    //                 await axios.post("/users-gs", {
    //                     email : result.user.email, 
    //                     name : result.user.displayName
    //                 })
    //             }
    //         }
    //         return result.user;
    //     }).catch((err) => {
    //         console.error(err.message)
    //     });
}

const logoutUserAsync = async () => {
    return Auth.signOut()
}

const signupUserAsync = async (data) => {
    return axios.post("/users", data).then(res => {
        // fireAlertSuccess("Great !", "You can now login to the system !!!")
        /* eslint-disable no-undef */
        window.location = '/'
        return res
    }).catch((error) => {
        if (error.message.includes("409"))
            fireAlertError("Oops", "This user exists")
    })
}
///////////////////
//ASYNC FINISHED//
///////////////////

export function* loginAuth(action) {
    const {data} = action
    try {
        yield put(handleLoginLoader(true))
        const res = yield call(loginAsync, data);
        yield put(loginUserSuccess(res));
    } catch (e) {
        yield put(loginUserError(e));
    } finally {
        yield put(handleLoginLoader(false))
    }
}

export function* logoutUserCB() {
    try {
        yield call(logoutUserAsync)
        yield put(logoutSuccess())
    } catch (err) {
        console.error(err.message)
    }
}

export function* signupUserCB(action) {

    const {payload} = action
    try {
        yield put(handleSignupLoader(true))
        yield call(signupUserAsync, payload)
    } catch (err) {
        console.error(err.message)
    } finally {
        yield put(handleSignupLoader(false))
    }
}

export function* signInWithGoogleCB() {
    try {
        const res = yield call(loginWithGoogleAsync);
        yield put(loginUserSuccess(res));
    } catch (err) {
        console.error(err.message)
    }
}

// Individual exports for testing
function* getLoginSagas() {
    yield takeEvery(LOGIN_USER, loginAuth);
    yield takeEvery(LOGOUT_USER_LISTEN, logoutUserCB);
    yield takeEvery(SIGNUP_USER_LISTEN, signupUserCB);
    yield takeEvery(SIGNIN_WITH_GOOGLE_LISTEN, signInWithGoogleCB);
}

export const loginSagas = [getLoginSagas];