import {
    GET_USER, GET_USER_ERROR, GET_USER_SUCCESS, HANDLE_LOGIN_LOADER, HANDLE_SIGNUP_LOADER,
    LOGIN_USER,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_LISTEN,
    RETURN_STATE, SIGNIN_WITH_GOOGLE_LISTEN, SIGNUP_USER_LISTEN, SIGNUP_USER_SUCCESS
} from "./constants";

export function loginUserListen(data) {
    return {
        type: LOGIN_USER,
        data
    }
}

export function loginUserSuccess(data) {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: data
    }
}

export function loginUserError(message) {
    return {
        type: LOGIN_USER_ERROR,
        payload: {message}
    }
}

export function returnState(data) {
    return {
        type: RETURN_STATE,
        payload: data
    }
}

//logout actions
export const logoutListen = () => {

    return {
        type: LOGOUT_USER_LISTEN
    }
}

export const logoutSuccess = () => {

    return {
        type: LOGOUT_USER_SUCCESS
    }
}

export function getUser() {
    return {
        type: GET_USER
    }
}

export function getUserSuccess(data) {
    return {
        type: GET_USER_SUCCESS,
        payload: data
    }
}

export function getUserError(e) {
    return {
        type: GET_USER_ERROR,
        payload: e
    }
}

//Signup user actions
export const signupUserListen = (data) => {

    return {
        type: SIGNUP_USER_LISTEN,
        payload: data
    }
}

export const signupUserSuccess = () => {
    return {
        type: SIGNUP_USER_SUCCESS
    }
}

//Use this to signIn with google popup
export const signInWithGoogleSignInListen = () => {
    return {
        type: SIGNIN_WITH_GOOGLE_LISTEN
    }
}

//LOADERS
export const handleLoginLoader = (state) => {

    return {
        type: HANDLE_LOGIN_LOADER,
        payload: state
    }
}

export const handleSignupLoader = (state) => {

    return {
        type: HANDLE_SIGNUP_LOADER,
        payload: state
    }
}