import {
    Box, Button, CircularProgress, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter, TableHead,
    TablePagination,
    TableRow, Typography
} from "@mui/material";
import {useState} from "react";
import "../assets/css/dataTable.css"
import {COMPLETED, HASERROR, INPROGRESS, PENDING} from "../constants";
import {useSelector} from "react-redux";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { deleteJob, setSelectedJobId } from "../redux/job/actions";
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import {useHistory} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';

const JobTable = ({status}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        outline: "none",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px"
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const dispatch = useDispatch();
    const history = useHistory();

    const {jobs, fetchJobLoading} = useSelector(state => state.jobReducer);

    const handlePageChange = (event, curPage) => {
        setPage(curPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
    }

    const handleRowClick = (id) => {
        setIndex(id)
        handleModel()
    }

    const handleModel = () => {
        setOpen(!open)
    }

    const getIndex = (index) => {
        return parseInt(rowsPerPage,10)*page+index;
    }

    const handleResultView = async (data) => {
        dispatch(setSelectedJobId(data.id));
        history.push(`/jobs/single-job-result`)
    }

    if (fetchJobLoading && !jobs) {
        return <div><CircularProgress className="mr-2" size={20} style={{color: "green"}}/></div>
    }

    return <div className="shadow-lg">
        <TableContainer>
            <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{fontWeight:"bold"}}>SEQUENCE NAME</TableCell>
                        <TableCell align="center" style={{fontWeight:"bold"}}>STATUS</TableCell>
                        <TableCell align="center" style={{fontWeight:"bold"}}>ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/** Todo : undo filtered by PENDING jobs */}
                    { jobs &&
                        (rowsPerPage === 'All' ? jobs : jobs.slice(parseInt(rowsPerPage,10)*page , parseInt(rowsPerPage,10)*page + parseInt(rowsPerPage,10)))?.filter(row => row?.status !== PENDING).map((row, index) => {
                            return <TableRow
                                className="table-row pointer"
                                key={row?.id}>
                                <TableCell onClick={() => handleResultView(row)} style={{width: 160}} align="center">
                                    {row?.seq_name}
                                </TableCell>
                                <TableCell onClick={() => handleResultView(row)} style={{width: 160}} align="center">
                                    {row?.status === COMPLETED && <Tooltip title="Completed"><CheckCircleIcon style={{ textTransform: "none", color: "green", height: 28 }} /></Tooltip>}
                                    {row?.status === INPROGRESS && <div style={{ color: "orange"}} ><Tooltip title="Job In Progress"><CircularProgress size={16} color="inherit"/></Tooltip></div>}
                                    {row?.status === PENDING && <Tooltip title="Pending"><PendingIcon /></Tooltip>}
                                    {row?.status === HASERROR && <Tooltip title="Error !"><ErrorIcon style={{ textTransform: "none", color: "red", height: 28 }} /></Tooltip>}
                                </TableCell>
                                <TableCell style={{width: 160}} align="center">
                                    <Button
                                    disableRipple
                                    disableTouchRipple
                                    style={{
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        height: 28
                                    }}
                                    target="_blank"
                                    onClick={() => dispatch(deleteJob(row?.id, 'delete', status === 'results' ? 'complete' : 'active'))}
                                    >
                                        <Tooltip title="Delete Job">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                    disableRipple
                                    disableTouchRipple
                                    style={{
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        height: 28
                                    }}
                                    target="_blank"
                                    onClick={() => dispatch(deleteJob(row?.id, 'archive', status === 'results' ? 'complete' : 'active'))}
                                    >
                                        <Tooltip title="Archive Job">
                                            <ArchiveIcon />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        disableRipple
                                        disableTouchRipple
                                        style={{
                                            textTransform: "none",
                                            backgroundColor: "transparent",
                                            height: 28
                                        }}
                                        target="_blank"
                                        onClick={() => handleRowClick(index)}
                                        >
                                        More
                                    </Button>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                            colSpan={3}
                            count={jobs? jobs.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        {/*/////////////*/}
        {/*result model start*/}
        {/*/////////////*/}
        { jobs && 
            <Modal
                open={open}
                onClose={handleModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        ID {jobs[getIndex(index)]?.id}
                    </Typography>
                    <p className="text-grey text-small">
                        Created at: {" "}
                        {moment(jobs[getIndex(index)]?.created_at).format("MMM Do YY")}
                    </p>
                    <h4 className="mt-3 text-medium text-success">
                        {jobs[getIndex(index)]?.seq_name}
                    </h4>
                    {
                        jobs[getIndex(index)]?.error_type && <div>
                            {jobs[getIndex(index)]?.status === HASERROR && <p className={`text-semi-medium text-danger`}>
                                {jobs[getIndex(index)]?.error_type}
                            </p>}
                            <ul>
                                <li className={`text-danger`}>
                                    {jobs[getIndex(index)]?.error_message}
                                </li>
                            </ul>
                        </div>
                    }
                    <div className="d-flex justify-end">
                        <Button
                            onClick={() => setOpen(!open)}
                            sx={{
                                "&.MuiButton-text": {color: "#338d00"},
                                ':hover': {
                                    bgcolor: '#338d00', // theme.palette.primary.main
                                    color: 'white',
                                }
                        }}>close</Button>
                        <Button
                        onClick={() => {
                            dispatch(deleteJob(jobs[getIndex(index)]?.id, 'delete', status === 'results' ? 'complete' : 'active'));
                            setOpen(!open)
                        }}
                            style={{marginLeft:20}}
                            sx={{
                                "&.MuiButton-text": {color: "#d41313"},
                                ':hover': {
                                    bgcolor: '#d41313', // theme.palette.primary.main
                                    color: 'white',
                                }
                            }
                            }>Move to trash</Button>
                        <Button
                            onClick={() => {
                                dispatch(deleteJob(jobs[getIndex(index)]?.id, 'archive', status === 'results' ? 'complete' : 'active'));
                                setOpen(!open)
                            }}
                            style={{marginLeft:20}}
                            sx={{
                                "&.MuiButton-text": {color: "#616161"},
                                ':hover': {
                                    bgcolor: '#616161', // theme.palette.primary.main
                                    color: 'white',
                                }
                            }}>Archive</Button>
                    </div>
                </Box>
            </Modal> 
        }
    </div>
}

export default JobTable