import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import BaseSeq from "../generatorWidgets/BaseSeq";
import NumericInput from "react-numeric-input";

const RandomGenerator = ({ id, data, handleClose, handleChange }) => {
  const [baseSeqText, setBaseSeqText] = useState(data.baseSeqText ?? "");
  const [baseSeqType, setBaseSeqType] = useState(data.baseSeqType ?? "wildType");
  const [candidatesGenerated, setCandidatesGenerated] = useState(data.candidatesGenerated ?? null);
  const [positionsMutated, setPositionsMutated] = useState(data.positionsMutated ?? null);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const data = {
      baseSeqText,
      seqType: "aa",
      baseSeqType, 
      candidatesGenerated,
      positionsMutated,
      completed: baseSeqType !== "" && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true) && candidatesGenerated>0 && positionsMutated>0

    }
    handleChange(id, data);
    setCompleted(baseSeqType !== "" && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true) && candidatesGenerated>0 && positionsMutated>0);
  }, [baseSeqText, baseSeqType, candidatesGenerated, positionsMutated]);

  const handleBaseSeqType = (value) => {
    setBaseSeqType(value);
  };
  const handleBaseSeqText = (value) => {
    setBaseSeqText(value);
  };
  const handleCandidatesGenerated = (value) => {
    setCandidatesGenerated(value || 1);
  };
  const handlePositionMutated = (value) => {
    setPositionsMutated(value || 1);
  };

  const handleCloseBtn = () => {
    handleClose(id);
  };

  return (
    <div className="w-80 gray-border" style={{ borderColor: completed ? 'grey' : "red" }}>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <CloseIcon
          style={{ color: "gray" }}
          className="clickable"
          onClick={() => {
            handleCloseBtn();
          }}
        />
      </div>
      <div className="pl-3" style={{ fontWeight: "500" }}>
        Random Mutations
      </div>
      <div style={{ marginTop: "10px" }}>
        <BaseSeq
          key={id}
          value={baseSeqType}
          data={baseSeqText}
          handleChangeType={(value) => handleBaseSeqType(value)}
          handleChangeText={(value) => handleBaseSeqText(value)}
        />
      </div>

      <div className="mb-3 pl-3 pt-1">
        <div className="d-flex">
          <div>Candidates generated</div>
          <div className="ml-3">
            <NumericInput className="form-control" value={candidatesGenerated} min={1} onChange={(value)=>handleCandidatesGenerated(Number(value))}/>
          </div>
        </div>
        <div className="d-flex" style={{marginTop:"20px"}}>
          <div>Positions mutated</div>
          <div className="ml-3">
            <NumericInput className="form-control" value={positionsMutated} min={1} onChange={(value)=>handlePositionMutated(Number(value))}/>          
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomGenerator;
