import SideDrawCustom from "../../components/SideDrawCustom";
import MenuBar from "../../components/menuBar";
import AddCartTable from "../../components/AddToCartTable";
import HorizontalLabelPositionBelowStepper from "../stepper";

const AddToCartView = () => {

    return <div>
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={6}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
                <p className="text-semi-medium">
                    Credits / Buy Credits / Add suggestions package to cart
                </p>
                <div style={{marginTop:"20px",marginBottom:"40px"}}><HorizontalLabelPositionBelowStepper activeSteps={1}/></div>
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <AddCartTable/>
                </div>
            </div>
        </div>
    </div>
}

export default AddToCartView