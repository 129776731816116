import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history'
import {connectRouter} from 'connected-react-router'
import loginReducer from "./login/reducer";
import jobReducer from "./job/reducer";
import {loginSagas} from "./login/saga";
import {jobSagas} from "./job/saga";
import {all, fork} from "redux-saga/effects";
import userReducer from "./users/reducer";
import userSagas from "./users/saga";
import creditReducer from "./credits/reducer";
import { creditSagas } from "./credits/saga";

export const history = createBrowserHistory()

const rootReducer = combineReducers({
    router: connectRouter(history),
    userReducer,
    loginReducer,
    jobReducer,
    creditReducer
});

function* rootSaga() {
    yield all(loginSagas.map(s => fork(s)));
    yield all(userSagas.map(s => fork(s)));
    yield all(jobSagas.map(s => fork(s)));
    yield all(creditSagas.map(s => fork(s)));
}

function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [
        sagaMiddleware,
        routerMiddleware(history)
    ]

    const store = createStore(
        rootReducer,
        {},
        compose(applyMiddleware(...middleware))
    );
    sagaMiddleware.run(rootSaga);
    return store;
}

export default configureStore;