import { FormControl, OutlinedInput, Tooltip, Button, CircularProgress } from "@mui/material";
import MenuBar from "../../components/menuBar";
import SideDrawCustom from "../../components/SideDrawCustom";
import "../../assets/css/createJob.css";
import { useState } from "react";
import { useEffect } from "react";
import FixedGenerator from "../../components/generatorClasses/FixedGenerator";
import DeepScanGenerator from "../../components/generatorClasses/DeepScanGenerator";
import RandomGenerator from "../../components/generatorClasses/RandomGenerator";
import ExhaustiveGenerator from "../../components/generatorClasses/ExhaustiveGenerator";
import MutationCombiner from "../../components/generatorClasses/MutationCombiner";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { clearCSV, logFailedGeneratorSubmission, startGeneratorProcess } from "../../redux/job/actions";

const GeneratorConfigView = () => {
  const history = useHistory();
  const dispatch = useDispatch()

  const [generators, setGenerators] = useState([]);
  const [count, setCount] = useState(0);
  const [noOfSuggestions, setNoOfSuggestions] = useState(0);
  const [completed, setCompleted] = useState(false);

  const {selectedJobId, selectedJobData, selectedJobDataLoading, generatorProcessStarting, goToResults} = useSelector(state => state.jobReducer);

  useEffect(()=>{
    dispatch(clearCSV())
  },[])

  useEffect(() => {
    if (!selectedJobDataLoading && !selectedJobData && !selectedJobId) history.push('/jobs/results')
  }, [selectedJobDataLoading])

  useEffect(() => {
    if (goToResults) history.push('/jobs/results')
  }, [goToResults])

  useEffect(() => {
    console.log(generators)
    const count = generators.length;
    setCount(count);
  }, [generators]);

  const handleAddGenerator = (value) => {
    setGenerators([  [count, value, { completed: false, initial: true }] , ...generators ]);
  };

  const handleClose = (value) => {
    const filter = generators.filter((obj) => {
      return obj[0] !== value;
    });
    setGenerators(filter);
  };

  const handleChange = (id, value) => {
    const generatorCopy = [...generators];
    generatorCopy.forEach((obj) => {
     if(obj[0]===id){
      obj[2]=value;
     }
    });
    setCompleted(generatorCopy.every(el => el[2]?.completed));
    setGenerators(generatorCopy);
  };

  const handleSubmit = () => {
    let request = {
      jobId: selectedJobId,
      numOfSuggestions: noOfSuggestions,
      generators: generators && generators.map(el => ({
        "generatorType": el[1],
        "baseSeqType": el[2].baseSeqType ?? '',
        "baseSeqText": el[2].baseSeqText ?? '',
        "seqType": el[2].seqType ?? '',
        "candidatesGenerated":  el[2].candidatesGenerated ?? 0,
        "positionsMutated": el[2].positionsMutated ?? 0,
        "intList": el[2].intList ?? [],
        "aaPositionType": el[2].aaPositionType ?? '',
        "sequences":  el[2].sequences ?? []
      }))
    }

    dispatch(startGeneratorProcess(request));
  }

  const handleFailedSubmission = () => {
    let request = {
      jobId: selectedJobId,
      numOfSuggestions: noOfSuggestions,
      generators: generators && generators.map(el => ({
        "generatorType": el[1],
        "baseSeqType": el[2].baseSeqType ?? '',
        "baseSeqText": el[2].baseSeqText ?? '',
        "seqType": el[2].seqType ?? '',
        "candidatesGenerated":  el[2].candidatesGenerated ?? 0,
        "positionsMutated": el[2].positionsMutated ?? 0,
        "intList": el[2].intList ?? [],
        "aaPositionType": el[2].aaPositionType ?? '',
        "sequences":  el[2].sequences ?? []
      }))
    }

    dispatch(logFailedGeneratorSubmission(request));
  }

  return (
    <div className="w-100 mb-3 overflow-auto">
      <MenuBar />
      <div className="d-flex">
        <SideDrawCustom activeRoute={2} />
        <div className="w-75 h-85 pl-3 pt-1 pr-3 mb-3 d-flex flex-column">
          <p className="text-semi-medium">Jobs / Create a New Job</p>
          <div className="pl-1 d-flex">
            <label
              className="btn btn-outlined-success d-center"
            >
             <span className="mr-1">Add candidate generation method</span>
            <div className="mr-1">
              <label
                className="btn p-1 btn-outlined-success d-center clickable"
                onClick={() => {
                  handleAddGenerator("fixed");
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  {" "}
                  USER-DEFINED SEQUENCES
                </div>
              </label>
            </div>
            <div className="mr-1">
              <label
                className="btn btn-outlined-success d-center clickable"
                onClick={() => {
                  handleAddGenerator("deepScan");
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  DEEP MUTATION SCAN
                </div>
              </label>
            </div>
            <div className="mr-1">
              <label
                className="btn btn-outlined-success d-center clickable"
                onClick={() => {
                  handleAddGenerator("random");
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  {" "}
                  RANDOM MUTATION
                </div>
              </label>
            </div>
            <div className="mr-1">
              {" "}
              <label
                className="btn btn-outlined-success d-center clickable"
                onClick={() => {
                  handleAddGenerator("exhaustive");
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  {" "}
                  EXHAUSTIVE MUTATION
                </div>
              </label>
            </div>
            <div>
              {" "}
              <label
                className="btn btn-outlined-success d-center clickable"
                onClick={() => {
                  handleAddGenerator("mutation");
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  {" "}
                  MUTATION COMBINER
                </div>
              </label>
            </div>
            </label>
            <div className="w-60 ml-2">
                <FormControl sx={{ m: 1, width: '15ch' }} variant="outlined">
                <label className="mb-1">Number of Suggestions :</label>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={noOfSuggestions}
                    onChange={(e) => {
                      let val = parseInt(e.target.value, 10);
                      if (isNaN(val)) {
                        setNoOfSuggestions(0)
                      } else {
                        val = val >= 0 ? val : 0;
                        setNoOfSuggestions(val)
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
            </div>
          </div>
          
          <div className="w-100 overflow-auto mt-2" >
            {generators.map((generator) => {
              // eslint-disable-next-line no-lone-blocks
              if (generator[1] === "fixed")
                return (
                  <div className="pl-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <FixedGenerator 
                      key={generator[0]}
                      id={generator[0]}
                      data={generator[2]} 
                      handleClose={(value) => handleClose(value)}
                      handleChange={(id,value)=>handleChange(id,value)}
                    />
                  </div>
                );
              // eslint-disable-next-line no-lone-blocks
              if (generator[1] === "deepScan")
                return (
                  <div className="pl-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <DeepScanGenerator
                      key={generator[0]}
                      id={generator[0]}
                      data={generator[2]} 
                      handleClose={(value) => handleClose(value)}
                      handleChange={(id,value)=>handleChange(id,value)}
                    />
                  </div>
                );
              // eslint-disable-next-line no-lone-blocks
              if (generator[1] === "random")
                return (
                  <div className="pl-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <RandomGenerator 
                      key={generator[0]}
                      id={generator[0]} 
                      data={generator[2]} 
                      handleClose={(value) => handleClose(value)}
                      handleChange={(id,value)=>handleChange(id,value)}
                    />
                  </div>
                );
              // eslint-disable-next-line no-lone-blocks
              if (generator[1] === "exhaustive")
                return (
                  <div className="pl-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <ExhaustiveGenerator 
                      key={generator[0]}
                      id={generator[0]} 
                      data={generator[2]} 
                      handleClose={(value) => handleClose(value)}
                      handleChange={(id,value)=>handleChange(id,value)}
                    />
                  </div>
                );
              // eslint-disable-next-line no-lone-blocks
              if (generator[1] === "mutation")
                return (
                  <div className="pl-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <MutationCombiner 
                      key={generator[0]}
                      id={generator[0]} 
                      data={generator[2]} 
                      handleClose={(value) => handleClose(value)}
                      handleChange={(id,value)=>handleChange(id,value)}
                    />
                  </div>
                );
            })}
          </div>
          <div className="p-3">
          <Tooltip title={(!noOfSuggestions || !completed) ? "Fill all input fields" : ""}>
            <div className="d-flex justify-end" onClick={() => {
              if (!completed) {
                handleFailedSubmission()
              }
            }}>
              { generators.length ? <Button
                onClick={() => handleSubmit()}
                disabled ={!noOfSuggestions || !completed}
                sx={{
                  "&.MuiButton-text": { color: "white" },
                  background: "#339604",
                  marginLeft: "30px",
                  ":hover": {
                    bgcolor: "#338d00", // theme.palette.primary.main
                    color: "white",
                  },
                }}
              >
                {generatorProcessStarting && (
                      <CircularProgress
                        size={14}
                        className="mr-1"
                        color="inherit"
                      />
                    )}
                Submit
              </Button> : null}
            </div>
          </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratorConfigView;
