import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SeqType from "../generatorWidgets/SeqType";
import BaseSeq from "../generatorWidgets/BaseSeq";
import IntegerList from "../generatorWidgets/IntegerList";
import { Grid } from "@mui/material";
import { getIntegerList, validateIntegerList } from "../../utils/validations";

const MutationCombiner = ({ id, data, handleClose, handleChange }) => {
  const [GeneralParameters, setGeneralParameter] = useState(data.seqType ?? "aa");
  const [baseSeqText, setBaseSeqText] = useState(data.baseSeqText ?? "");
  const [baseSeqType, setBaseSeqType] = useState(data.baseSeqType ?? "wildType");
  const [integerList, setIntegerList] = useState(data.intListRaw ?? "");
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const data = {
      seqType: GeneralParameters,
      baseSeqText,
      baseSeqType,
      intList: getIntegerList(integerList),
      intListRaw: integerList,
      completed: GeneralParameters !== "" && baseSeqType !== "" && validateIntegerList(integerList) && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true)
    }
    handleChange(id, data);
    setCompleted(GeneralParameters !== "" && baseSeqType !== "" && validateIntegerList(integerList) && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true));
  }, [GeneralParameters, baseSeqText, baseSeqType, integerList]);

  
  const handleGeneralParaChange = (value) => {
    setGeneralParameter(value);
  };
  const handleBaseSeqType = (value) => {
    setBaseSeqType(value);
  };
  const handleBaseSeqText = (value) => {
    setBaseSeqText(value);
  };
  const handleIntListChange = (value) => {
    setIntegerList(value);
  };
  const handleCloseBtn = () => {
    handleClose(id);
  };

  return (
    <div className="w-80 gray-border" style={{ borderColor: completed ? 'grey' : "red" }}>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <CloseIcon
          style={{ color: "gray" }}
          className="clickable"
          onClick={() => {
            handleCloseBtn();
          }}
        />
      </div>
      <div className="pl-3" style={{ fontWeight: "500" }}>
        Mutation Combiner
      </div>
      <div style={{ marginTop: "10px" }}>
        <BaseSeq
          key={id}
          data={baseSeqText}
          value={baseSeqType}
          handleChangeType={(value) => handleBaseSeqType(value)}
          handleChangeText={(value) => handleBaseSeqText(value)}
        />
        <SeqType key={id} seqType={GeneralParameters} handleChange={(value) => handleGeneralParaChange(value)} />
      </div>
      <div className="mb-3 pl-3 w-90" style={{display: 'flex', flexDirection: 'row'}}>
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <label>
              Number of allowed mutations
            </label>
          </Grid>
          <Grid item xs={4}>
            <IntegerList key={id} data={integerList} handleChange={(value) => handleIntListChange(value)} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MutationCombiner;
