import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useEffect} from "react";

const steps = [
  '',
  '',
  '',
];
const style = {
    width: '30%',margin:"0 auto"
    
};

export default function HorizontalLabelPositionBelowStepper({activeSteps}) {
    const [activeStep, setActiveStep] = React.useState(0);
    
  useEffect(() => {
    setActiveStep(activeSteps)
 }, [activeSteps])

  return (
    <Box sx={style}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}
          sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#339604', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#339604', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'common.white', // circle's number (ACTIVE)
            },}}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}