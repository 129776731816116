import React, { useState } from 'react';
import { Button, CircularProgress } from "@mui/material"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import * as PaymentIntetnService from '../../services/PaymentService'
import { fireAlertError, fireAlertSuccess } from "../../utils/baseUtils";
import { useDispatch } from 'react-redux';
import { getCart } from '../../redux/credits/actions';

const StripeCheckoutForm = ({ items, handleClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [paymentLoading, setPaymentLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setPaymentLoading(true);
        const cardElement = elements.getElement(CardElement)

        stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        }).then(paymentMethod => {

            return new Promise((resolve, reject) => {
                PaymentIntetnService.createPaymentIntent(items, "usd",  paymentMethod.paymentMethod.id).then(res => {
                    console.log(res)
                    const data = {
                        clientSecret: res.data.intent.client_secret,
                        paymentMethodId: paymentMethod.paymentMethod.id,
                        paymentIntentId: res.data.intent.id,
                        paymentId: res.data.paymentId
                    }
                    resolve(data)
                }).catch(err => reject(err))
            })
            
        }).then(res => {
            return PaymentIntetnService.confirmPayment(res.paymentIntentId, res.paymentMethodId, res.paymentId)
        }).then(() => {
            handleClose()
            dispatch(getCart());
            setPaymentLoading(false);
            fireAlertSuccess("Payment Success !")
        }).catch(() => {
            handleClose()
            setPaymentLoading(false);
            fireAlertError("Payment Failed !")
        })
        
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: "10px",
                backgroundColor: '#339604'
            }}>
                <CardElement options={{
                    style: {
                        base: {
                            color: "white",
                            "::placeholder": {
                                color: 'white'
                            }
                        }
                    }
                }} />
            </div>

            <Button type="submit"
                sx={{
                    marginTop: '20px',
                    width: '100%',
                    backgroundColor: '#339604',
                    color: 'white',
                    ':hover': {
                        bgcolor: '#338d00', // theme.palette.primary.main
                        color: 'white',
                    },
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                disabled={!stripe || !elements || paymentLoading}> {paymentLoading && <CircularProgress className="mr-2" size={20} style={{color: "white"}}/>}Pay</Button>
        </form>
    )
}

export default StripeCheckoutForm