import { FormControl, Select, MenuItem } from "@mui/material";
import { useState } from "react";

const DropDown = ({ handleChange, target }) => {
  const [order, setOrder] = useState("None");
  const handleOrderChange = (value) => {
    setOrder(value);
    handleChange(value);
  };

  return (
    <div className="d-flex justify-between">
      <div
        className="mb-4 pl-3 pt-1"
        style={{ width: "20%" }}
      >
        <FormControl sx={{ minWidth: 220 }} size="small">
          <Select
            value={order}
            onChange={(e) => handleOrderChange(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              "&:hover": {
                "&& fieldset": {
                  border: "3px solid #339604",
                },
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "white",
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "rgba(51, 150, 4, 0.12)",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "rgba(51, 150, 4, 0.12)",
                  },
                },
              },
            }}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Min">Minimize</MenuItem>
            <MenuItem value="Max">Maximize</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        className="mb-4 pl-3 pt-1"
        style={{ width: "75%", marginTop: "10px" }}
      >
        {target.replace('target_', '')}
      </div>
    </div>
  );
};

export default DropDown;
