export function validateIntegerList(str) {
    return /^[0-9-,]+$/.test(str);
}
  
export function getIntegerList(data) {
    if (!/^[0-9-,]+$/.test(data)) return [];
    const segmants = data.replace(" ","").split(",");
    const integerList = [];

    for (const el of segmants) { 
        if (!el.includes("-")) {
        integerList.push(Number(el))
        continue;
        }
        if (el[0] === '-') {
        const limit = Number(el[1]);
        for (let i = 1; i<=limit; i++) {
            integerList.push(i);
        } 
        continue;
        }
        const splittedEl = el.split('-');
        for (let i = Number(splittedEl[0]); i<= Number(splittedEl[1] || 1000); i++) {
            integerList.push(i);
        } 
    }
    return integerList;
}

