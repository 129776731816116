import { FormControl, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";

const SuggestionDropDown = ({suggestionValues, selectedValue, ipShareId, handleChange}) => {

  const [suggestion, setSuggestion] = useState(0);

  useEffect(() => {
    setSuggestion(selectedValue)
  },[selectedValue] );
  const handleOrderChange = (value) => {
    setSuggestion(value);
    handleChange(value, ipShareId);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ minWidth: 120, align: "right" }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={suggestion}
          onChange={(e) => handleOrderChange(e.target.value)}
        >
          {
            suggestionValues.map(el => <MenuItem key={el.packageId} value={el.packageId}>{el.noOfSuggestions || 'Custom'}</MenuItem>)
          }
        </Select>
      </FormControl>
    </div>
  );
};

export default SuggestionDropDown;
