import SideDrawCustom from "../../components/SideDrawCustom";
import MenuBar from "../../components/menuBar";
import SingleJobSummary from "../../components/SingleJobSummary";

const SingleJobResultView = () => {

    return <div>
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={1}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
                <p className="text-semi-medium">
                    Jobs / Results
                </p>
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <SingleJobSummary/>
                </div>
            </div>
        </div>
    </div>
}

export default SingleJobResultView