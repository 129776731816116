import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AddIcon from "@mui/icons-material/Add";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {NavLink} from "react-router-dom";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const SideDrawCustom = ({activeRoute}) => {

    const activeRouteHandler = (index) => {
        if(activeRoute === index) {
            return "green"
        }
        return "black"
    }

    const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
        return <NavLink to={props.to} {...props} innerRef={ref}/>; // eslint-disable-line
    });

    return <div className="w-25 h-100">
        <div className="pt-5 shadow-lg h-100">
            <List>       
                <div style={{marginBottom: 40}}>
                    <ListItem component={LinkBtn} button to="/jobs/create">
                        <ListItemIcon>
                            <AddIcon style={{color: activeRouteHandler(2)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Engineer new protein" style={{color: activeRouteHandler(2)}}/>
                    </ListItem>
                </div>
                <ListItem
                    component={LinkBtn}
                    disabled
                    button
                    to="/"
                >
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Jobs"/>
                </ListItem>
                <div style={{marginLeft: 40, marginBottom: 40}}>
                    <ListItem component={LinkBtn} button to="/jobs/result">
                        <ListItemIcon>
                            <DoneAllIcon style={{color: activeRouteHandler(1)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Results" style={{color: activeRouteHandler(1)}}/>
                    </ListItem>
                    {/* <ListItem component={LinkBtn} button to="/job-status/progress">
                        <ListItemIcon>
                            <HourglassTopIcon style={{color: activeRouteHandler(4)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Jobs In Progress" style={{color: activeRouteHandler(4)}}/>
                    </ListItem> */}
                    <ListItem component={LinkBtn} button to="/job-status/archived">
                        <ListItemIcon>
                            <ArchiveIcon style={{color: activeRouteHandler(3)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Archive"  style={{color: activeRouteHandler(3)}}/>
                    </ListItem>
                    
                    <ListItem component={LinkBtn} button to="/job-status/trash">
                        <ListItemIcon>
                            <DeleteIcon style={{color: activeRouteHandler(5)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Trash" style={{color: activeRouteHandler(5)}}/>
                    </ListItem>
                </div>
                <ListItem
                    component={LinkBtn}
                    disabled
                    button
                    to="/"
                >
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Credits"/>
                </ListItem>
                <div style={{marginLeft: 40, marginBottom: 60}}>
                    <ListItem component={LinkBtn} button to="/credits/buy-credits/suggestion-credit">
                        <ListItemIcon>
                            <ShoppingCartCheckoutIcon style={{color: activeRouteHandler(6)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Buy Credits" style={{color: activeRouteHandler(6)}}/>
                    </ListItem>
                    <ListItem component={LinkBtn} button to="/credits/credit-balance">
                        <ListItemIcon>
                            <AccountBalanceWalletIcon style={{color: activeRouteHandler(7)}}/>
                        </ListItemIcon>
                        <ListItemText primary="Credit Balance" style={{color: activeRouteHandler(7)}}/>
                    </ListItem>
                </div>
            </List>
        </div>
    </div>
}

export default SideDrawCustom