import { FormControl } from "@mui/material";
import { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const SeqType = ({ handleChange, seqType }) => {
  const [value, setValue] = useState(seqType);
  const handleValueChange = (value) => {
    setValue(value);
    handleChange(value);
  };

  return (
    <div className="mb-2 pl-3 pt-1" style={{ width: "40%" }}>
     <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={(e) => handleValueChange(e.target.value)}
      >
        <FormControlLabel disabled={true} value="dna" control={<Radio />} label="DNA" />
        <FormControlLabel value="aa" control={<Radio />} label="Amino Acids" />
        
      </RadioGroup>
    </FormControl>
    </div>
  );
};

export default SeqType;
