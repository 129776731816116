import produce from "immer";
import { fireAlertSuccess } from "../../utils/baseUtils";

import {
    CREATE_JOB,
    CREATE_JOB_ERROR,
    CREATE_JOB_SUCCESS,
    DISPATCH_FILE,
    HANDLE_SUBMIT_FILE_UPLOAD_LOADER,
    IMAGE_UPLOAD_PROGRESS_LISTEN, 
    SUBMIT_FILE_UPLOAD_SUCCESS,
    START_JOB,
    START_JOB_SUCCESS,
    START_JOB_ERROR,
    GET_INACTIVE_JOBS,
    GET_INACTIVE_JOBS_SUCCESS,
    GET_INACTIVE_JOBS_ERROR,
    GET_COMPLETED_JOBS_SUCCESS,
    GET_COMPLETED_JOBS_ERROR,
    GET_COMPLETED_JOBS,
    SET_SELECTED_JOB_ID,
    FETCH_SELECTED_JOB,
    FETCH_SELECTED_JOB_SUCCESS,
    FETCH_SELECTED_JOB_ERROR,
    START_GENERATOR_PROCESS,
    START_GENERATOR_PROCESS_SUCCESS,
    START_GENERATOR_PROCESS_ERROR,
    CLEAR_CSV,
    GO_TO_RESULTS,
    CLEAR_GO_TO_RESULTS
} from "./constants";
import {COMPLETED, ERROR, PROGRESS} from "./consts";

const INIT_STATE = {
    jobID: null,
    inputId:null,
    jobs: [],
    fetchJobLoading: false,
    createJobError: null,
    createJobLoading: false,
    csvFile: null,
    progress: 0,
    status: PROGRESS,
    submitLoader: false,
    startJobLoading: false,
    targetFields: null,
    selectedJobId: null,
    selectedJobDataLoading: false,
    selectedJobData: null,
    generatorProcessStarting: false,
    goToResults: false
};

export default function jobReducer(state = INIT_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case CREATE_JOB:
                draft.createJobLoading = true;
                break;
            case CREATE_JOB_ERROR:
                draft.createJobLoading = false;
                draft.hasSuccess = false
                draft.status = ERROR
                break;
            case CREATE_JOB_SUCCESS:
                draft.createJobLoading = false;
                draft.hasSuccess = true;
                draft.status = COMPLETED;
                draft.jobID = action.payload;
                draft.inputId = action.input;
                draft.targetFields = action.targets;
                break;
            case START_JOB:
                draft.startJobLoading = true;
                break;
            case START_JOB_SUCCESS:
                draft.startJobLoading = false;
                draft.jobID = null;
                draft.csvFile = null;
                draft.status = PROGRESS;
                draft.submitLoader = false;
                draft.progress = 0;
                draft.targetFields = null;
                break;
            case START_JOB_ERROR:
                draft.startJobLoading = false;
                break;
            case DISPATCH_FILE:
                draft.csvFile = action.payload
                draft.progress = 0
                draft.status = PROGRESS
                break
            case IMAGE_UPLOAD_PROGRESS_LISTEN:
                draft.progress = action.payload
                break
            case HANDLE_SUBMIT_FILE_UPLOAD_LOADER:
                draft.submitLoader = action.payload
                break
            case SUBMIT_FILE_UPLOAD_SUCCESS:
                draft.csvFile = null
                draft.progress = 0
                break
            case GET_INACTIVE_JOBS_SUCCESS:
                draft.fetchJobLoading = false;
                draft.jobs = action.payload
                break
            case GET_INACTIVE_JOBS_ERROR:
                draft.fetchJobLoading = false;
                break
            case GET_INACTIVE_JOBS:
                draft.fetchJobLoading = true;
                break
            case GET_COMPLETED_JOBS_SUCCESS:
                draft.fetchJobLoading = false;
                draft.jobs = action.payload
                break
            case GET_COMPLETED_JOBS_ERROR:
                draft.fetchJobLoading = false;
                break
            case GET_COMPLETED_JOBS:
                draft.fetchJobLoading = true;
                break
            case SET_SELECTED_JOB_ID:
                draft.selectedJobId = action.jobId;
                break
            case FETCH_SELECTED_JOB:
                draft.selectedJobDataLoading = true;
                break
            case FETCH_SELECTED_JOB_SUCCESS:
                draft.selectedJobData = action.data;
                draft.selectedJobDataLoading = false;
                break
            case FETCH_SELECTED_JOB_ERROR:
                draft.selectedJobDataLoading = false;
                break
            case START_GENERATOR_PROCESS:
                draft.generatorProcessStarting = true;
                break
            case START_GENERATOR_PROCESS_SUCCESS:
                fireAlertSuccess('Success !','Process started successfully')
                draft.generatorProcessStarting = false;
                break
            case START_GENERATOR_PROCESS_ERROR:
                fireAlertSuccess('Failed !','Process failed to start')
                draft.generatorProcessStarting = false;
                break
            case CLEAR_CSV:
                draft.csvFile = null;
                draft.targetFields = null;
                break
            case GO_TO_RESULTS:
                draft.goToResults = true;
                break
            case CLEAR_GO_TO_RESULTS:
                draft.goToResults = false;
                break
            default:
                break;
        }
    });
}