import brand from "../../assets/brand/Exazyme Logo compact 1.png"
import {Lock} from "@mui/icons-material";
import {Button, CircularProgress, Divider, TextField} from "@mui/material";
import "../../assets/css/login.css"
import google from "../../assets/icons/google.png"
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {signInWithGoogleSignInListen, signupUserListen} from "../../redux/login/actions";
import {ERROR_INIT} from "../loginPage/consts";
import {EMAIL_PATTERN, PASSWORD_MAX_STRENGTH_PATTERN} from "../../utils/regxPatterns";

const styles = {
    "&.MuiButton-root": {
        border: "1px #bdc3c7 solid"
    },
    "&.MuiButton-text": {
        color: "grey"
    },
    "&.MuiButton-contained": {
        color: "yellow"
    },
    "&.MuiButton-outlined": {
        color: "#7f8c8d"
    },
};

const Signup = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [name, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const [emailError, setEmailError] = useState(ERROR_INIT)
    const [pwError, setPwError] = useState(ERROR_INIT)
    const [nameError, setNameError] = useState(ERROR_INIT)
    const [lastNameError, setLastNameError] = useState(ERROR_INIT)

    const history = useHistory()
    const dispatch = useDispatch()

    const {signupLoader} = useSelector(state => state.loginReducer)

    const validate = () => {
        cleanState()
        if (email) {
            let regex = new RegExp(EMAIL_PATTERN);
            const dis = regex.test(email)
            if (!dis) {
                setEmailError({
                    errorMsg: "*. invalid email format",
                    hasError: true
                })
                return false
            }
        } else {
            setEmailError({
                errorMsg: "*. Insert your email here",
                hasError: true
            })
            return false
        }

        if (password) {
            let regex = new RegExp(PASSWORD_MAX_STRENGTH_PATTERN);
            const dis = regex.test(password)
            if (!dis) {
                setPwError({
                    errorMsg: "*. Password must contain minimum 8 letters, 1 uppercase character, 1 lowercase character, 1 special character, 1 number",
                    hasError: true
                })
                return false
            }
        } else {
            setPwError({
                errorMsg: "*. Insert your password here",
                hasError: true
            })
            return false
        }

        if (name) {
            if (name.length < 2) {
                setNameError({
                    errorMsg: "*. First Name must contain minimum 2 letters",
                    hasError: true
                })
                return false
            }
        } else {
            setNameError({
                errorMsg: "*. You must add your first name",
                hasError: true
            })
            return false
        }

        if (lastName) {
            if (lastName.length < 2) {
                setLastNameError({
                    errorMsg: "*. Last Name must contain minimum 2 letters",
                    hasError: true
                })
                return false
            }
        } else {
            setLastNameError({
                errorMsg: "*. You must add your last name",
                hasError: true
            })
            return false
        }

        return true
    }

    const cleanState = () => {
        setEmailError(ERROR_INIT)
        setPwError(ERROR_INIT)
        setNameError(ERROR_INIT)
        setLastNameError(ERROR_INIT)
    }

    const signupUser = () => {
        if (validate())
            dispatch(signupUserListen({email, firstName: name, lastName, password}))
    }

    const googleSignIn = () => {
        dispatch(signInWithGoogleSignInListen())
    }

    return <div className="d-flex">
        <div className="w-50 d-center">
            <img src={brand}/>
        </div>
        <div className="w-50 d-center flex-column h-100">
            <div className="mb-3">
                <div className="d-center">
                    <Lock className="text-light m-0 bg-danger p-1 radius-round"/>
                </div>
                <p className="text-medium m-0">Sign up</p>
            </div>
            <div className="w-100 d-center">
                <div className="w-75">
                    <div className="d-flex justify-between w-100">
                        <span className="w-50 mr-2">
                            <TextField
                                onChange={e => setEmail(e.target.value)}
                                error={emailError.hasError}
                                id="outlined-basic" type="email" label="Email Address*" variant="outlined"
                                className="w-100"/>
                            <p className="text-small text-error p-1 m-0">{emailError.errorMsg}</p>
                        </span>
                        <div className="w-50">
                            <TextField
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                                error={emailError.hasError}
                                id="outlined-basic" label="Password*" variant="outlined" className="w-100"/>
                            <p className="text-small text-error p-1 m-0">{pwError.errorMsg}</p>
                        </div>
                    </div>
                    <div className="mb-2 mt-2">
                        <TextField
                            onChange={e => setFirstName(e.target.value)}
                            error={nameError.hasError}
                            id="outlined-basic" label="First Name" variant="outlined" className="w-100"/>
                        <p className="text-small text-error p-1 m-0">{nameError.errorMsg}</p>
                    </div>
                    <div>
                        <TextField
                            onChange={e => setLastName(e.target.value)}
                            error={lastNameError.hasError}
                            id="outlined-basic" label="Last Name*" variant="outlined" className="w-100"/>
                        <p className="text-small text-error p-1 m-0">{lastNameError.errorMsg}</p>
                    </div>
                    <div className="w-100 mt-4">
                        <Button
                            onClick={signupUser}
                            sx={{
                                "&.MuiButton-text": {color: "white"},
                                background: "#339604",
                                ':hover': {
                                    bgcolor: '#338d00', // theme.palette.primary.main
                                    color: 'white',
                                }
                            }}
                            className="w-100">{signupLoader && <CircularProgress className="mr-2" size={20}
                                                                                 style={{color: "white"}}/>} Sign
                            up</Button>
                    </div>
                    <div className="d-flex mt-2">
                        <Divider className="flex-grow-2"/>
                        <div className="text-grey mr-2 ml-2">
                            or
                        </div>
                        <Divider className="flex-grow-2"/>
                    </div>
                    <div className="mt-3">
                        <Button
                            onClick={googleSignIn}
                            sx={styles}
                            variant="outlined" color="primary" className="w-100 relative">
                            <img src={google} width="25px" className="mr-5 absolute google-icon"/>
                            Sign Up With Google</Button>
                    </div>
                    <div className="mt-4">
                        <p className="text-center text-grey text-small">
                            Already have an account? <span
                            onClick={() => history.push("/")}
                            className="text-success text-semi-bold clickable">Sign in</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Signup