import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import "../assets/css/dataTable.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import go from "../assets/icons/go.png";
import { useHistory } from "react-router-dom";
import SuggestionDropDown from "./SuggestionDropDown";
import { setPackageId } from "../redux/credits/actions";


const BuyCreditTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [packages, setPackages] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const {packageData} = useSelector(state => state.creditReducer);

  const handlePackageChange = (packageId, ipShareId) => {
    const current = packages;
    current[ipShareId] = packageId;
    setPackages(current);
  }

  
  const handlePageChange = (event, curPage) => {
    setPage(curPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleRowClick = (id) => {
    const savedIpShares = Object.keys(packages).map(el => parseInt(el, 10));
    if (savedIpShares.includes(id)) {
      dispatch(setPackageId(id, packages[id]));
    } else {
      const packageId = packageData.filter(el => el.ipShareId === id)?.[0]?.customPackageId;
      dispatch(setPackageId(id, packageId));
      
    }
    history.push("/credits/buy-credits/add-to-cart");
  };

  return (
    <div className="shadow-lg">
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight:"bold"}} align="left">MY IP SHARE</TableCell>
              <TableCell style={{fontWeight:"bold"}} align="center">SUGGESTIONS CREDIT</TableCell>
              <TableCell style={{fontWeight:"bold"}} align="center">BUY CREDIT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage === "All"
              ? packageData
              : packageData && packageData.slice(
                  parseInt(rowsPerPage, 10) * page,
                  parseInt(rowsPerPage, 10) * page + parseInt(rowsPerPage, 10)
                )
            )?.map((row) => {
              return (
                <TableRow className="table-row pointer" key={row?.ipShareId}>
                  <TableCell style={{ width: 80 }} align="left">
                    {row?.ipShare}%
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <SuggestionDropDown  
                      ipShareId={row.ipShareId}
                      selectedValue={row.customPackageId}
                      suggestionValues={row.packages}
                      handleChange={handlePackageChange}
                    /> 
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Button
                      disableRipple
                      disableTouchRipple
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        height: 28,
                      }}
                      target="_blank"
                      onClick={() => handleRowClick(row.ipShareId)}
                    >
                      <img
                        src={go}
                        width="25px"
                        className="mr-5 absolute google-icon"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={(packageData && packageData.length) || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BuyCreditTable;
