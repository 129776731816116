import axios from '../axios/axios';
import {Auth} from 'aws-amplify';

export const createPaymentIntent = async (items, currency, paymentMethod) => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;

    return axios.post("/credits/payment", {
        items,
        currency,
        paymentMethod
    }, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const confirmPayment = async (paymentItentId, paymentMenthodId, paymentId) => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;

    return axios.post("/credits/payment/confirm", {
        paymentIntent: paymentItentId,
        paymentMenthod : paymentMenthodId,
        paymentId
    }, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}