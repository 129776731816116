import {
    call, put, takeEvery
} from 'redux-saga/effects';
import { GET_PACKAGE_DATA, ADD_TO_CART, GET_CART, DELETE_CART, GET_BALANCE } from "./constants";
import { getPackageDataSuccess, getPackageDataError, getCartSuccess, getCartError, addToCartSuccess, addToCartError, getCart, deleteFromCartSuccess, deleteFromCartError, getCreditBalanceSuccess, getCreditBalanceError } from './actions';
import {Auth} from 'aws-amplify';
import axios from "../../axios/axios";

// ------------------------ Async Functions -------------------------
const getPackageDataAsync = async () => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.get(`/credits/packages`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const getCartDataAsync = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.get(`/credits/cart`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const addToCartAsync = async (suggestions, packageId) => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const payload = {
        suggestions,
        packageId
    }

    return axios.post(`/credits/cart`, payload, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const deleteFromCartAsync = async (cartId) => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.delete(`/credits/cart/${cartId}`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const getCreditBalanceAsync = async () => {

    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    
    return axios.get(`/credits/balance`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

// ------------------------ Saga Functions -------------------------

export function* getPackageDataSaga() {
    try {
        const res = yield call(getPackageDataAsync)
        yield put(getPackageDataSuccess(res.data))
    } catch (err) {
        console.error(err.message)
        yield put(getPackageDataError())
    } 
}

export function* getCartDataSaga() {
    try {
        const res = yield call(getCartDataAsync)
        yield put(getCartSuccess(res.data))
    } catch (err) {
        console.error(err.message)
        yield put(getCartError())
    } 
}

export function* addToCartSaga(action) {
    const { suggestions, packageId } = action;
    try {
        const res = yield call(addToCartAsync, suggestions, packageId)
        yield put(getCart())
        yield put(addToCartSuccess(res.data))
    } catch (err) {
        console.error(err.message)
        yield put(addToCartError())
    } 
}

export function* deleteFromCartSaga(action) {
    const { cartId } = action;
    try {
        yield call(deleteFromCartAsync, cartId)
        yield put(getCart())
        yield put(deleteFromCartSuccess())
    } catch (err) {
        console.error(err.message)
        yield put(deleteFromCartError())
    } 
}

export function* getCreditBalanceSaga() {
    try {
        const res = yield call(getCreditBalanceAsync)
        yield put(getCreditBalanceSuccess(res.data))
    } catch (err) {
        console.error(err.message)
        yield put(getCreditBalanceError())
    } 
}

function* creditPackagesSagas() {
    yield takeEvery(GET_PACKAGE_DATA, getPackageDataSaga);
    yield takeEvery(ADD_TO_CART, addToCartSaga);
    yield takeEvery(GET_CART, getCartDataSaga);
    yield takeEvery(DELETE_CART, deleteFromCartSaga);
    yield takeEvery(GET_BALANCE, getCreditBalanceSaga);
}

export const creditSagas = [creditPackagesSagas];
