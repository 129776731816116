import './App.css';
import {Route, Switch} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch} from 'react-redux';
import Login from "./containers/loginPage/Login";
import "./assets/css/theme.css"
import "./assets/css/animation.css"
import Signup from "./containers/signup/Signup";
import UserView from "./containers/user/UserView";
import ResultView from "./containers/resultView/ResultView";
import ArchivedJobs from "./containers/archivedJobs/ArchivedJobs";
import TrashedJobs from "./containers/deletedJobs/deletedJobs";
import CreateJobView from "./containers/createJob/CreateJobView";
import BuyCreditsView from './containers/credits/BuyCreditsView';
import { getCurUserListen } from './redux/users/actions';
import { Auth } from "aws-amplify";
import { useSelector } from 'react-redux';
import { loginUserSuccess } from './redux/login/actions';
import AddToCartView from './containers/addToCart/AddToCartView';
import Checkout from './containers/checkout/CheckoutView';
import Balance from './containers/balance/CreditBalanceView';
import Cart from './containers/cart/CartView';
import GeneratorConfigView from './containers/generatorConfiguration/GeneratorConfigView';
import SingleJobResultView from './containers/resultView/SingleJobResultView';
import { getCart, getCreditBalance } from './redux/credits/actions';


function App() {

    const dispatch = useDispatch()
    const {loggedIn} = useSelector(state => state.loginReducer);

    const [logged, setLogged] = useState(false);
    
    useEffect(() => {
        (async () => {
          let user = null;
          try {
            user = await Auth.currentAuthenticatedUser();
            if (user) {
                dispatch(loginUserSuccess(user));
                setLogged(true);
            } else {
                setLogged(false);
            }

            if(logged) {
                dispatch(getCurUserListen());
                dispatch(getCart());
                dispatch(getCreditBalance());
            }
          } catch (e) {
            setLogged(false);
          }
        })();
      }, [logged, loggedIn]);

    const routeController = () => {

        if (logged) {
            return [
                <Route path="/user" key={1} component={UserView}/>,
                <Route path="/jobs/result" key={2} component={ResultView}/>,
                <Route path="/jobs/single-job-result" key={3} component={SingleJobResultView}/>,
                <Route path="/jobs/create" key={4} component={CreateJobView}/>,
                <Route path="/jobs/create/:id" key={5} component={CreateJobView}/>,
                <Route path="/jobs/create-generators" key={6} component={GeneratorConfigView}/>,
                <Route path="/job-status/archived" key={7} component={ArchivedJobs}/>,
                <Route path="/job-status/trash" key={9} component={TrashedJobs}/>,
                <Route path="/credits/buy-credits/suggestion-credit" key={10} component={BuyCreditsView}/>,
                <Route path="/credits/buy-credits/add-to-cart" key={11} component={AddToCartView}/>,
                <Route path="/credits/buy-credits/checkout" key={12} component={Checkout}/>,
                <Route path="/credits/credit-balance" key={13} component={Balance}/>,
                <Route path="/cart" key={14} component={Cart}/>,
                <Route path="/" key={15} component={ResultView}/>
            ]
        } else {
            return [
                <Route path="/signup" key={1} component={Signup}/>,
                <Route path="/" key={2} component={Login}/>,
            ]
        }
    }

    return (
        <>
            <Switch>
                {routeController()}
            </Switch>
        </>
    );
}


export default App;
