import brand from "../../assets/brand/Exazyme Logo compact 1.png"
import {Lock} from "@mui/icons-material";
import {Button, Checkbox, CircularProgress, Divider, TextField} from "@mui/material";
import "../../assets/css/login.css"
import google from "../../assets/icons/google.png"
import {useDispatch, useSelector} from "react-redux";
import {loginUserListen} from "../../redux/login/actions";
import {useState} from "react";
import {ERROR_INIT} from "./consts";
import {EMAIL_PATTERN} from "../../utils/regxPatterns";
// import { Auth } from "aws-amplify";
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {useHistory} from "react-router-dom";
import { signInWithGoogleSignInListen } from "../../redux/login/actions";

const styles = {
    "&.MuiButton-root": {
        border: "1px #bdc3c7 solid"
    },
    "&.MuiButton-text": {
        color: "grey"
    },
    "&.MuiButton-contained": {
        color: "yellow"
    },
    "&.MuiButton-outlined": {
        color: "#7f8c8d"
    },
};

const Login = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [emailError, setEmailError] = useState(ERROR_INIT)
    // const [pwError, setPwError] = useState(ERROR_INIT)

    const dispatch = useDispatch()
    const history = useHistory()
    const {loginLoader} = useSelector(state => state.loginReducer)

    const validateForm = () => {
        cleanState()
        if (email) {
            let regex = new RegExp(EMAIL_PATTERN);
            const dis = regex.test(email)
            if (!dis) {
                setEmailError({
                    errorMsg: "*. invalid email format",
                    hasError: true
                })
                return
            }
        }
                if (!email) {
            setEmailError({
                errorMsg: "*. Empty email",
                hasError: true
            })
            return;
        }
        dispatch(loginUserListen({email, password}))
    }

    const cleanState = () => {
        setEmailError(ERROR_INIT)
        // setPwError(ERROR_INIT)
    }

    const loginUser = () => {
        validateForm()
    }

    const googleSignIn = () => {
        dispatch(signInWithGoogleSignInListen())
        // Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    }

    return <div className="d-flex">
        <div className="w-50 d-center">
            <img src={brand}/>
        </div>
        <div className="w-50 d-center flex-column h-100">
            <div className="mb-3">
                <div className="d-center">
                    <Lock className="text-light m-0 bg-danger p-1 radius-round"/>
                </div>
                <p className="text-medium m-0">Sign in</p>
            </div>
            <div className="w-100 d-center">
                <div className="w-75">
                    <div className="mb-4">
                        <TextField
                            onChange={e => setEmail(e.target.value)}
                            error={emailError.hasError}
                            id="outlined-basic" label="Email Address*" variant="outlined" className="w-100"/>
                        <p className="text-small text-error">{`${emailError.errorMsg}`}</p>
                    </div>
                    <div>
                        <TextField
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            id="outlined-basic" label="Password*" variant="outlined" className="w-100"/>
                    </div>
                    <div className="d-flex mb-2">
                        <Checkbox/>
                        <p>Remember me</p>
                    </div>
                    <div className="w-100">
                        <Button
                            onClick={loginUser}
                            sx={{
                                "&.MuiButton-text": {color: "white"},
                                background: "#339604",
                                ':hover': {
                                    bgcolor: '#338d00', // theme.palette.primary.main
                                    color: 'white',
                                }
                            }}
                            className="w-100">{
                            loginLoader && <CircularProgress className="mr-2" size={20} style={{color: "white"}}/>
                        }Sign in</Button>
                    </div>
                    <div>
                        <p className="text-small text-success">Forgot password ?</p>
                    </div>
                    <div className="d-flex mt-2">
                        <Divider className="flex-grow-2"/>
                        <div className="text-grey mr-2 ml-2">
                            or
                        </div>
                        <Divider className="flex-grow-2"/>
                    </div>
                    <div className="mt-3">
                        <Button
                            onClick={googleSignIn}
                            sx={styles}
                            variant="outlined" color="primary" className="w-100 relative">
                            <img src={google} width="25px" className="mr-5 absolute google-icon"/>
                            Sign In With Google</Button>
                    </div>
                    <div className="mt-4">
                        {/* eslint-disable react/no-unescaped-entities */}
                        <p className="text-center text-grey text-small"> Don't have an account?
                        <span onClick={() => history.push("/signup")} className="text-success text-semi-bold clickable">Sign up</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Login