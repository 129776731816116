export const CREATE_JOB = 'JOB/CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'JOB/CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'JOB/CREATE_JOB_ERROR';

export const DELETE_JOB = 'JOB/DELETE_JOB';
export const DELETE_JOB_SUCCESS = 'JOB/DELETE_JOB_SUCCESS';
export const DELETE_JOB_ERROR = 'JOB/DELETE_JOB_ERROR';

export const DELETE_PERM_JOB = 'JOB/DELETE_PERM_JOB';
export const DELETE_PERM_JOB_SUCCESS = 'JOB/DELETE_JOB_PERM_SUCCESS';
export const DELETE_PERM_JOB_ERROR = 'JOB/DELETE_PERM_JOB_ERROR';

export const START_JOB = 'JOB/START_JOB';
export const START_JOB_SUCCESS = 'JOB/START_JOB_SUCCESS';
export const START_JOB_ERROR = 'JOB/START_JOB_ERROR';

export const GET_INACTIVE_JOBS = 'JOB/GET_INACTIVE_JOBS';
export const GET_INACTIVE_JOBS_SUCCESS = 'JOB/GET_INACTIVE_JOBS_SUCCESS';
export const GET_INACTIVE_JOBS_ERROR = 'JOB/GET_INACTIVE_JOBS_ERROR';

export const GET_COMPLETED_JOBS = 'JOB/GET_COMPLETED_JOBS';
export const GET_COMPLETED_JOBS_SUCCESS = 'JOB/GET_COMPLETED_JOBS_SUCCESS';
export const GET_COMPLETED_JOBS_ERROR = 'JOB/GET_COMPLETED_JOBS_ERROR';

export const RESTORE_JOBS = 'JOB/RESTORE_JOBS';
export const RESTORE_JOBS_SUCCESS = 'JOB/RESTORE_JOBS_SUCCESS';
export const RESTORE_JOBS_ERROR = 'JOB/RESTORE_JOBS_ERROR';

export const DISPATCH_FILE = "Job/DISPATCH_FILE"

export const GET_RESULTS_LISTEN = "Job/GET_RESULTS_LISTEN"
export const GET_RESULTS_SUCCESS = "Job/GET_RESULTS_SUCCESS"

export const IMAGE_UPLOAD_PROGRESS_LISTEN = "Job/IMAGE_UPLOAD_PROGRESS_LISTEN"

export const SUBMIT_FILE_UPLOAD_LISTEN = "Job/SUBMIT_FILE_UPLOAD_LISTEN"
export const SUBMIT_FILE_UPLOAD_SUCCESS = "Job/SUBMIT_FILE_UPLOAD_SUCCESS"
export const HANDLE_SUBMIT_FILE_UPLOAD_LOADER = "Job/HANDLE_SUBMIT_FILE_UPLOAD_LOADER"

export const SET_SELECTED_JOB_ID = "Job/SET_SELECTED_JOB_ID"

export const FETCH_SELECTED_JOB = "Job/FETCH_SELECTED_JOB"
export const FETCH_SELECTED_JOB_SUCCESS = "Job/FETCH_SELECTED_JOB_SUCCESS"
export const FETCH_SELECTED_JOB_ERROR = "Job/FETCH_SELECTED_JOB_ERROR"

export const START_GENERATOR_PROCESS = "Job/START_GENERATOR_PROCESS"
export const START_GENERATOR_PROCESS_SUCCESS = "Job/START_GENERATOR_PROCESS_SUCCESS"
export const START_GENERATOR_PROCESS_ERROR = "Job/START_GENERATOR_PROCESS_ERROR"

export const LOG_FAILED_GENERATOR_SUBMISSION = "Job/LOG_FAILED_GENERAOT_SUBMISSION"

export const UPLOAD_NEW_INPUT = "Job/UPLOAD_NEW_INPUT"
export const UPLOAD_NEW_INPUT_SUCCESS = "Job/UPLOAD_NEW_INPUT_SUCCESS"
export const UPLOAD_NEW_INPUT_ERROR = "Job/UPLOAD_NEW_INPUT_ERROR"

export const CLEAR_CSV = "Job/CLEAR_CSV"

export const GO_TO_RESULTS = "Job/GO_TO_RESULTS"
export const CLEAR_GO_TO_RESULTS = "Job/CLEAR_GO_TO_RESULTS"
