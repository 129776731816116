export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const RETURN_STATE = "RETURN_STATE";

export const LOGOUT_USER_LISTEN = "Login/LOGOUT_USER_LISTEN"
export const LOGOUT_USER_SUCCESS = "Login/LOGOUT_USER_SUCCESS"

export const GET_USER = "LOGIN/GET_USER";
export const GET_USER_SUCCESS = "LOGIN/GET_USER_SUCCESS";
export const GET_USER_ERROR = "LOGIN/GET_USER_ERROR";

export const SIGNUP_USER_LISTEN = "Login/SIGNUP_USER_LISTEN"
export const SIGNUP_USER_SUCCESS = "Login/SIGNUP_USER_SUCCESS"

export const SIGNIN_WITH_GOOGLE_LISTEN = "Login/SIGNIN_WITH_GOOGLE_LISTEN"
export const SIGNIN_WITH_GOOGLE_SUCCESS = "Login/SIGNIN_WITH_GOOGLE_SUCCESS"

export const HANDLE_LOGIN_LOADER = "Login/HANDLE_LOGIN_LOADER"
export const HANDLE_SIGNUP_LOADER = "Login/HANDLE_SIGNUP_LOADER"