const awsconfig = {
    "aws_project_region": process.env.REACT_APP_COGNITO_REGION,
    // "aws_cognito_identity_pool_id": "ap-southeast-1:2f17049d-9129-47e4-a305-2329e97e551b",
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,

    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOL_ID,

    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_DOMAIN,

        "scope": [
            // "phone",
            "email",
            "openid",
            "profile",
            // "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app.exazyme.com/",
        "redirectSignOut": "http://localhost:3000/,https://app.exazyme.com/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    // "aws_cognito_mfa_types": [
    //     "SMS"
    // ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsconfig;
