import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import configureStore, {history} from "./redux/store";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/styles";

import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';

const isLocalhost = Boolean(
  /* eslint-disable no-undef */
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address. 
    /* eslint-disable no-undef */
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    /* eslint-disable no-undef */
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

const store = configureStore();

const theme = createTheme({
    //here you set palette, typography ect...
})

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ThemeProvider theme={theme} >
                <App/>
            </ThemeProvider>
        </Router>
    </Provider>,
    /* eslint-disable no-undef */
    document.getElementById('root')
);

reportWebVitals();

