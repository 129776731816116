import ArchivedAndDeletedJobs from "../../components/ArchivedAndDeletedJobs";
import MenuBar from "../../components/menuBar";
import SideDrawCustom from "../../components/SideDrawCustom";
import {DELETED} from "../../constants";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getInactiveJobs} from "../../redux/job/actions";

const TrashedJobs = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getInactiveJobs())
    }, [])

    return <div>
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={5}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
                <p className="text-semi-medium">
                    Jobs / Deleted
                </p>
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <ArchivedAndDeletedJobs status={DELETED}/>
                </div>
            </div>
        </div>
    </div>
}

export default TrashedJobs