import SideDrawCustom from "../../components/SideDrawCustom";
import MenuBar from "../../components/menuBar";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import CreditBalanceTable from "../../components/CreditBalanceTable";
import { getCreditBalance } from "../../redux/credits/actions";

const CreditBalanceView = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCreditBalance())
    }, [])

    return <div>
        
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={7}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
           
                <p className="text-semi-medium">
                    Credits / Credit Balance
                </p>
                
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <CreditBalanceTable/>
                </div>
            </div>
        </div>
    </div>
}

export default CreditBalanceView