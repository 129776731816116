import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import "../assets/css/dataTable.css";
import { useDispatch } from "react-redux";
import cart from "../assets/icons/delete.png";
import { useSelector } from "react-redux";
import { deleteFromCart } from "../redux/credits/actions";
import { Dialog, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';

import StripeCheckoutForm from "./stripeCheckoutForm/stripeCheckout";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const CheckoutTable = () => {

  const dispatch = useDispatch();
  const [sum, setSum] = useState(0);
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const {cartData} = useSelector(state => state.creditReducer);

  useEffect(() => {
    let sum = 0;
    // eslint-disable-next-line no-lone-blocks
    {
      cartData && cartData.map((row) => {
        sum = sum + parseInt(row.pricePerSuggestion * row.numberOfSuggestions);
      });
    }
    setSum(sum);
  }, [cartData]);

  return (
    <div className="shadow-lg">
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight:"bold"}}>MY IP SHARE</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>SUGGESTIONS CREDIT</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>PRICE PER SUGGESTION </TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>PACKAGE PRICE</TableCell>
              <TableCell align="center" style={{fontWeight:"bold"}}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartData && cartData.map((row) => {
              return (
                <TableRow className="table-row pointer" key={row?.id}>
                  <TableCell style={{ width: 80 }} align="left">
                  {row?.ipShare}%
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                  {row?.numberOfSuggestions}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {"€"}
                    {row?.pricePerSuggestion}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    {"€"}
                    {row?.pricePerSuggestion * row?.numberOfSuggestions}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Button
                      disableRipple
                      disableTouchRipple
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        height: 28,
                      }}
                      target="_blank"
                        onClick={() =>
                          dispatch(deleteFromCart(row?.id))
                        }
                    >
                      <img
                        src={cart}
                        width="25px"
                        className="mr-5 absolute google-icon"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow className="table-row pointer">
              <TableCell style={{ width: 80 }} align="left">
                {}
              </TableCell>
              <TableCell style={{ width: 160 }} align="center">
                {}
              </TableCell>
              <TableCell style={{ width: 160 ,fontWeight:"bold", fontSize:"15px"}} align="center">
                {"Sum"}
              </TableCell>
              <TableCell style={{ width: 160 ,fontWeight:"bold", fontSize:"15px"}} align="center" >
                {"€"}
                {sum}
              </TableCell>
              <TableCell style={{ width: 160 }} align="center">
                {}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div className="p-3">
        <div className="d-flex justify-end">
          <Button
            onClick={() => setOpen(true)}

            sx={{
              "&.MuiButton-text": { color: "white" },
              background: "#339604",
              marginLeft: "30px",
              ":hover": {
                bgcolor: "#338d00", // theme.palette.primary.main
                color: "white",
              },
            }}
          >
            CHECKOUT & PAY
          </Button>
        </div>
      </div>
      <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{
                textAlign: 'center',
                color: 'black',
                backgroundColor: '#FFFFFF'
            }}>
                {"Checkout"}
            </DialogTitle>
            <Box sx={{
                color: 'white',
                backgroundColor: '#FFFFFF',
                padding: "16px 16px"
            }}>
                <Elements stripe={stripePromise}>
                    <StripeCheckoutForm items={cartData ? cartData.map(el => el.id) : []} handleClose={handleClose}
                         />
                </Elements>
            </Box>
        </Dialog>
    </div>
  );
};

export default CheckoutTable;
