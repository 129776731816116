import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { validateIntegerList } from "../../utils/validations";

const IntegerList = ({ handleChange, data="" }) => {
  const [integerList, setIntegerList] = useState(data ?? "");
  const [error, setError] = useState('');

  useEffect(() =>{
    if (integerList === '' || validateIntegerList(integerList)) {
      setError('')
    } else {
      setError('Invalid Integer List')
    }
  }, [integerList])

  const handleOrderChange = (value) => {
    setIntegerList(value);
    handleChange(value);
  };
  
  return (
    <div>
      <TextField
        onChange={(e) => handleOrderChange(e.target.value)}
        value={integerList}
        id="outlined-size-small"
        error={error}
        variant="outlined"
        className="w-100"
        size="small"
      />
      <p className="text-small text-error p-1 m-0">{error}</p>
    </div>
  );
};

export default IntegerList;
