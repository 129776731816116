import Swal from "sweetalert2";

export const fireAlertSuccess = (title, message) => {
    Swal.fire(title, message, "success")
}

export const fireAlertError = (title, message) => {
    Swal.fire(title, message, "error")
}

export const fireAlertCustom = (title, messgage, icon) => {
    Swal.fire(title, messgage, icon)
}