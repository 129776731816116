import {
    call, put, takeEvery, takeLatest // select, take were removed
} from 'redux-saga/effects';
import {
    createJobSuccess,
    createJobError,
    imageUploadProgress,
    handleUploadFileSubmitLoader, 
    handleUploadFileSubmitSuccess,
    startJobSuccess,
    startJobError,
    deleteJobSuccess,
    deleteJobError,
    getInactiveJobs,
    getInactiveJobsSuccess,
    getInactiveJobsError,
    restoreJobsSuccess,
    restoreJobsError,
    getCompletedJobsSuccess,
    getCompletedJobsError,
    getCompletedJobs,
    deleteJobPermError,
    deleteJobPermSuccess,
    fetchSelectedJobSummarySuccess,
    fetchSelectedJobSummaryError,
    startGeneratorProcessSuccess,
    startGeneratorProcessError,
    uploadNewInputSuccess,
    uploadNewInputError,
    goToRsults
} from "./actions";
import {
    CREATE_JOB,
    SUBMIT_FILE_UPLOAD_LISTEN,
    START_JOB,
    DELETE_JOB,
    GET_INACTIVE_JOBS,
    RESTORE_JOBS,
    GET_COMPLETED_JOBS,
    DELETE_PERM_JOB,
    FETCH_SELECTED_JOB,
    START_GENERATOR_PROCESS,
    UPLOAD_NEW_INPUT,
    LOG_FAILED_GENERATOR_SUBMISSION
} from "./constants";
import axios from "../../axios/axios";
import {Auth} from 'aws-amplify';
import { fireAlertError, fireAlertSuccess } from "../../utils/baseUtils";
const FormData = require("form-data");

async function createJobAsync(file, dispatch, id) {
    const bodyFormData = new FormData();
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    bodyFormData.append('file', file);
    let result;
    if(id) {
        result = await axios.post(`/jobs/${id}`, bodyFormData, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                const total = progressEvent.total
                const current = progressEvent.loaded
                const percent = (current / total) * 100

                dispatch(imageUploadProgress(Math.round(percent)))
            }
        }).then(res => res).catch(err => {
            console.log(err);
            return false;
        })
    } else  {
        result = await axios.post('/jobs', bodyFormData, {
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                const total = progressEvent.total
                const current = progressEvent.loaded
                const percent = (current / total) * 100

                dispatch(imageUploadProgress(Math.round(percent)))
            }
        }).then(res => res)
        .catch(err => {
            console.log(err);
            return false;
        })
    }
    return result;
}

async function uploadNewInputAsync(file, dispatch, id) {
    const bodyFormData = new FormData();
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    bodyFormData.append('file', file);
    bodyFormData.append('jobId', id);
    const result = await axios.post(`/jobs/newUpload`, bodyFormData, {
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => {
            const total = progressEvent.total
            const current = progressEvent.loaded
            const percent = (current / total) * 100

            dispatch(imageUploadProgress(Math.round(percent)))
        }
    }).then(res => res).catch(err => {
        console.log(err);
        return false;
    })
    return result;
}

async function startJobAsync(id, seqName, inputId, targets) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const result = await axios.post(`/jobs/start`, {seqName, jobId: id, targets}, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

async function deleteJobAsync(id, method) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    let payload = { 
        jobId: id
    };
    if (method === 'delete') {
        payload.deleted = true;
        payload.archived = false;
    } else if ( method === 'archive') {
        payload.archived = true;
        payload.deleted = false;
    }
    const result = await axios.patch(`/jobs`, payload, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

async function deleteJobPermAsync(id) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const result = await axios.delete(`/jobs/${id}`, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

const getInactiveJobsAsync = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    return axios.get(`/jobs/archived`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const getCompletedJobsAsync = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    return axios.get(`/results`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    })
}

const submitFileUploadStartAsync = async (jobId) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    return axios.post(`/jobs/start`, {jobId}, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        console.error(err.message);
        return err;
    });
}

const restoreJobsAsync = async (jobId) => {
    let paylod = { 
        'jobId': jobId,
        deleted : false,
        archived: false
    };
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const result = await axios.patch(`/jobs`, paylod, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

const fetchSelectedJobAsync = async (jobId) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;

    const result = await axios.get(`/results/summary?id=${jobId}`, {
        headers: {
            "Authorization": 'Bearer ' + token,
        },
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

async function startGeneratorProcessAsync(data) {
    console.log(data);
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const result = await axios.post(`/jobs/generator`, data, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}

async function logFailedGeneratorSubmission(data) {
    console.log(data);
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const result = await axios.post(`/jobs/generator/failed`, data, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then(res => res).catch(err => {
        return err;
    })
    return result;
}


////////////////
//ASYNC FINISHED
////////////////

export function* createJobSaga(action) {
    const {payload, dispatch, id} = action;
    try {
        const res = yield call(createJobAsync, payload, dispatch, id);
        if(res) {
            yield put(createJobSuccess(res?.data?.job?.id, res?.data?.targets, res?.data?.input?.id));
            fireAlertSuccess("Success !", "File Uploaded Successfully !!!")
        }
        else {
            yield put(createJobError())
            fireAlertError("Failed !", "Upload Failed !!!")
        }
    } catch (e) {
        yield put(createJobError());
    }
}

export function* startJobSaga(action) {

    const {seqName, id, inputId, targets} = action;

    try {
        const res = yield call(startJobAsync, id, seqName, inputId, targets);
        if(res) {
            yield put(startJobSuccess());
            fireAlertSuccess("Success !", "Job Started !!!")
            yield put(goToRsults());
        }
        else {
            yield put(startJobError())
            fireAlertError("Failed !", "Job Failed to Start !!!")
        }
    } catch (e) {
        yield put(startJobError());
    }

}

export function* deleteJobSaga(action) {

    const {method, id, page} = action;

    try {
        const res = yield call(deleteJobAsync, id, method);
        if(res) {
            yield put(deleteJobSuccess());
            if (page === 'active' ||page === 'complete' ) {
                yield put(getCompletedJobs());
            } else {
                yield put(getInactiveJobs());
            }
            fireAlertSuccess("Success !", `Job ${method === 'delete' ? 'Deleted' : 'Archived'} !!!`)
        }
        else {
            yield put(deleteJobError())
            fireAlertError("Failed !", `Job Failed to ${method === 'delete' ? 'Delete' : 'Archive'} !!!`)
        }
    } catch (e) {
        yield put(startJobError());
    }

}

export function* getInactiveJobsSaga() {
    try {
        const res = yield call(getInactiveJobsAsync)
        let jobs = [];
        if (res.data?.length) {
            jobs = res.data.sort((a,b) => b.id - a.id);
        }
        yield put(getInactiveJobsSuccess(jobs))
    } catch (err) {
        yield put(getInactiveJobsError())
    }
}

export function* getCompletedJobsSaga() {
    try {
        const res = yield call(getCompletedJobsAsync)
        let jobs = [];
        if (res.data?.length) {
            jobs = res.data.sort((a,b) => b.id - a.id);
        }
        yield put(getCompletedJobsSuccess(jobs))
    } catch (err) {
        yield put(getCompletedJobsError())
    }
}

export function* restoreJobSaga(action) {
    const {jobId} = action;
    try {
        yield call(restoreJobsAsync, jobId)
        yield put(getInactiveJobs());
        yield put(restoreJobsSuccess())
        fireAlertSuccess("Success !", `Job restored !!!`)
    } catch (err) {
        yield put(restoreJobsError())
        fireAlertError("Failed !", `Job not restored !!!`)
    }
}

export function* deleteJobPermSaga(action) {
    const {id} = action;
    try {
        yield call(deleteJobPermAsync, id)
        yield put(getInactiveJobs());
        yield put(deleteJobPermSuccess())
        fireAlertSuccess("Success !", `Job deleted !!!`)
    } catch (err) {
        yield put(deleteJobPermError())
        fireAlertError("Failed !", `Job not deleted !!!`)
    }
}

export function* uploadSubmitFileStartCB(action) {
    const {payload, history} = action
    try {
        yield put(handleUploadFileSubmitLoader(true))
        yield call(submitFileUploadStartAsync, payload)
        yield put(handleUploadFileSubmitSuccess())
        history.push("/job-status/progress")
    } catch (err) {
        console.error(err.message)
    } finally {
        yield put(handleUploadFileSubmitLoader(false))
    }
}

export function* fetchSelectedJob(action) {
    const {jobId} = action
    try {
        const res = yield call(fetchSelectedJobAsync, jobId)
        yield put(fetchSelectedJobSummarySuccess(res.data))
    } catch (err) {
        yield put(fetchSelectedJobSummaryError())
    }
}

export function* startGeneratorProcessSaga(action) {
    const {data} = action
    try {
        yield call(startGeneratorProcessAsync, data)
        yield put(startGeneratorProcessSuccess())
        yield put(goToRsults())
    } catch (err) {
        yield put(startGeneratorProcessError())
    }
}

export function* logFailedGeneratorSubmissionSaga(action) {
    const {data} = action
    try {
        yield call(logFailedGeneratorSubmission, data)
    } catch (err) {
        console.log(err);
    }
}

export function* uploadNewInputSaga(action) {
    const {payload, dispatch, id} = action;
    try {
        const res = yield call(uploadNewInputAsync, payload, dispatch, id);
        if(res) {
            yield put(uploadNewInputSuccess());
            fireAlertSuccess("Success !", "File Uploaded Successfully !!!")
        }
        else {
            yield put(uploadNewInputError())
            fireAlertError("Failed !", "Upload Failed !!!")
        }
    } catch (e) {
        yield put(uploadNewInputError());
    }
}

function* getJobSagas() {

    yield takeEvery(CREATE_JOB, createJobSaga);
    yield takeEvery(START_JOB, startJobSaga);
    yield takeEvery(DELETE_JOB, deleteJobSaga);
    yield takeEvery(DELETE_PERM_JOB, deleteJobPermSaga);
    yield takeEvery(GET_INACTIVE_JOBS, getInactiveJobsSaga);
    yield takeEvery(GET_COMPLETED_JOBS, getCompletedJobsSaga);
    yield takeEvery(RESTORE_JOBS, restoreJobSaga);
    yield takeLatest(SUBMIT_FILE_UPLOAD_LISTEN, uploadSubmitFileStartCB);
    yield takeLatest(FETCH_SELECTED_JOB, fetchSelectedJob);
    yield takeLatest(START_GENERATOR_PROCESS, startGeneratorProcessSaga);
    yield takeLatest(LOG_FAILED_GENERATOR_SUBMISSION, logFailedGeneratorSubmissionSaga);
    yield takeLatest(UPLOAD_NEW_INPUT, uploadNewInputSaga);
}

export const jobSagas = [getJobSagas];