import { FormControl, Select, MenuItem, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SeqType from "../generatorWidgets/SeqType";
import BaseSeq from "../generatorWidgets/BaseSeq";
import IntegerList from "../generatorWidgets/IntegerList";
import { getIntegerList, validateIntegerList } from "../../utils/validations";

const DeepScanGenerator = ({ id, data, handleClose, handleChange }) => {

  const [GeneralParameters, setGeneralParameter] = useState("aa");
  const [baseSeqText, setBaseSeqText] = useState(data.baseSeqText ?? "");
  const [baseSeqType, setBaseSeqType] = useState(data.baseSeqType ?? "wildType");
  const [integerList, setIntegerList] = useState(data.intListRaw ?? "");
  const [seqType, setSeqType] = useState(data.aaPositionType ?? "all");
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const init = {
      baseSeqType,
      baseSeqText,
      seqType: GeneralParameters,
      aaPositionType: seqType,
      intList: getIntegerList(integerList),
      intListRaw: integerList,
      completed: GeneralParameters !== "" && baseSeqType !== "" && seqType !== "" && (seqType !== "all" ? validateIntegerList(integerList) ? true : false : true) && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true)
    }
    handleChange(id, init);
    setCompleted(GeneralParameters !== "" && baseSeqType !== "" && seqType !== "" && (seqType !== "all" ? validateIntegerList(integerList) ? true : false : true) && (["named", "fixed"].includes(baseSeqType) ? baseSeqText ? true : false : true));
  }, [GeneralParameters, baseSeqText, baseSeqType, integerList, seqType]);

  const handleGeneralParaChange = (value) => {
    setGeneralParameter(value);
  };
  const handleBaseSeqType = (value) => {
    setBaseSeqType(value);
  };
  const handleBaseSeqText = (value) => {
    setBaseSeqText(value);
  };
  const handleIntListChange = (value) => {
    setIntegerList(value);
  };
  const handleSeqType = (value) => {
    setSeqType(value);
  };
  const handleCloseBtn = () => {
    handleClose(id);
  };

  return (
    <div className="w-80 gray-border" style={{ borderColor: completed ? 'grey' : "red" }}>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <CloseIcon
          style={{ color: "gray" }}
          className="clickable"
          onClick={() => {
            handleCloseBtn();
          }}
        />
      </div>
      <div className="pl-3" style={{ fontWeight: "500" }}>
        Deep Mutation Scan
      </div>
      <div style={{ marginTop: "10px" }}>
        <BaseSeq
          key={id}
          value={baseSeqType}
          data={baseSeqText}
          handleChangeType={(value) => handleBaseSeqType(value)}
          handleChangeText={(value) => handleBaseSeqText(value)}
        />
        <SeqType key={id} seqType={GeneralParameters} handleChange={(value) => handleGeneralParaChange(value)} />
      </div>

      <div className="pl-3 pt-1 mb-3" style={{ width: "60%" }}>
        <div className="d-flex">
          <div style={{ width: "48%" }}>
            <FormControl sx={{ minWidth: 220 }} size="small">
              <Select
                value={seqType}
                onChange={(e) => handleSeqType(e.target.value)}
                displayEmpty
              >
                <MenuItem value={"all"}>Include all positions</MenuItem>
                <MenuItem value={"only"}>Include only the following</MenuItem>
                <MenuItem value={"abf"}>Include all but the following</MenuItem>
              </Select>
            </FormControl>
          </div>
          { seqType !== "all" && 
          <Tooltip title="Insert Amino Acid positions">
            <div>
              <IntegerList key={id} data={data.intListRaw} handleChange={(value) => handleIntListChange(value)} />
            </div>
          </Tooltip>
          }
        </div>
      </div>
    </div>
  );
};

export default DeepScanGenerator;
