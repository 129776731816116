import SideDrawCustom from "../../components/SideDrawCustom";
import MenuBar from "../../components/menuBar";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import { getPackageData } from "../../redux/credits/actions";
import BuyCreditTable from "../../components/BuyCreditTable";
import HorizontalLabelPositionBelowStepper from "../stepper";

const BuyCreditsView = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPackageData())
    }, [])

    return <div>
        
        <MenuBar/>
        <div className="d-flex">
            <SideDrawCustom activeRoute={6}/>
            <div className="w-75 h-100 pl-3 pt-1 pr-3 d-flex flex-column">
           
                <p className="text-semi-medium">
                    Credits / Buy Credits / Overview suggestion Credit
                </p>
                <div style={{marginTop:"20px",marginBottom:"40px"}}><HorizontalLabelPositionBelowStepper activeSteps={0}/></div>
                
                <div className="w-100 overflow-auto" style={{height: "80%"}}>
                    <BuyCreditTable/>
                </div>
            </div>
        </div>
    </div>
}

export default BuyCreditsView