import {
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  Typography
} from "@mui/material";
import file from "../assets/icons/file.png"
import "../assets/css/dataTable.css";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useHistory} from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect, useState, useCallback } from "react";
import axios from "../axios/axios";
import { fetchSelectedJobSummary, uploadNewInput, dispatchFileToTheReducerAction } from "../redux/job/actions";
import { COMPLETED, HASERROR, INPROGRESS, PENDING } from "../constants";
import {useDropzone} from 'react-dropzone';

const SingleJobSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { csvFile, progress, status, selectedJobId, selectedJobData, selectedJobDataLoading} = useSelector(state => state.jobReducer);
  const [resultHtml, setResultHtml] = useState(null);
  const [openResult, setOpenResult] = useState(false);
  const [iterations, setIterations] = useState([]);
  const [initialResult, setInitialResult] = useState(null);

  const dispatchFileToTheReducer = (file) => {
    dispatch(dispatchFileToTheReducerAction(file));
    dispatch(uploadNewInput(file, dispatch, selectedJobId));
  };

  useEffect(() => {
    if (selectedJobData?.iterations) {
      const filteredIterations = selectedJobData.iterations.filter(el => !el.initial)
      const initial = selectedJobData.iterations.filter(el => el.initial);
      if (initial.length) setInitialResult(initial[0]);
      setIterations(filteredIterations);
    }
  },[selectedJobData]);
  
  useEffect(() => {
    if (selectedJobId) dispatch(fetchSelectedJobSummary(selectedJobId))
  }, [selectedJobId])

  useEffect(() => {
    if (!selectedJobDataLoading && !selectedJobData) history.push('/jobs/results')
  }, [selectedJobDataLoading])

  useEffect(() => {
    if (csvFile) history.push('/jobs/create-generators')
  }, [csvFile])
  
  const handleInitialResultView = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const response = await axios({
        url: `/results/downloadInitial?jobId=${selectedJobId.toString()}`,
        method: 'GET',
        responseType: 'stream',
        headers:{
             "Authorization": 'Bearer ' + token,
        }
      })
    setResultHtml(response.data);
    setOpenResult(true);
    return ;
  }

  const handleIterationResultDownload = async (inputId, iterationId) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const response = await axios({
      url: `/results/download?jobId=${selectedJobId.toString()}&inputId=${inputId.toString()}&iterationId=${iterationId.toString()}`,
      method: 'GET',
      responseType: 'stream',
      headers:{
          "Authorization": 'Bearer ' + token,
      }
    })
    /* eslint-disable no-undef */
    const url = window.URL.createObjectURL(new Blob([response.data]));
    /* eslint-disable no-undef */
    const link = document.createElement('a');
    link.href = url;
    /* eslint-disable no-undef */
    link.setAttribute('download', `${selectedJobData?.job?.seq_name}_result.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  
  const handleInitialResultDownload = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const response = await axios({
      url: `/results/downloadInitial?jobId=${selectedJobId.toString()}`,
      method: 'GET',
      responseType: 'stream',
      headers:{
          "Authorization": 'Bearer ' + token,
      }
    })

    /* eslint-disable no-undef */
    const url = window.URL.createObjectURL(new Blob([response.data]));
    /* eslint-disable no-undef */
    const link = document.createElement('a');
    link.href = url;
    /* eslint-disable no-undef */
    link.setAttribute('download', `${selectedJobData?.job?.seq_name}_result.html`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const onDrop = useCallback(acceptedFiles => {
    dispatchFileToTheReducer(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  function Result(props) {
    return <div dangerouslySetInnerHTML={props.template} />
  }

  if (selectedJobDataLoading) {
    return <div><CircularProgress className="mr-2" size={20} style={{color: "green"}}/></div>
  }

  if (resultHtml && openResult) {
    return (
        <div>
            <Button sx={{
                  "&.MuiButton-text": { color: "white" },
                  background: "#339604",
                  ":hover": {
                    bgcolor: "#338d00", // theme.palette.primary.main
                    color: "white",
                  },
                }} onClick={() => setOpenResult(false)}>Back</Button>
            <Result template={{ __html : resultHtml }} />
        </div>
    )
  }

  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  function compare(a, b) {
    if (a.id> b.id) return 1;
    return -1;
  }

  const handleTextColor = () => {
    if (status === "Jobs/PROGRESS") {
      return "text-grey";
    } else if (status === "Jobs/COMPLETED") {
      return "text-success";
    } else if (status === "Jobs/ERROR") return "text-danger";
    else {
      return "text-grey";
    }
  };

  const handleStatusMessage = () => {
    if (status === "Jobs/PROGRESS") {
      return `${(csvFile.size / 1024).toFixed(2)} kb`;
    } else if (status === "Jobs/COMPLETED") {
      return "File successfully uploaded";
    } else if (status === "Jobs/ERROR") return "Failed to upload";
    else {
      return setMessage(`${(csvFile.size / 1024).toFixed(2)} kb`);
    }
  };

  return (
    <div>
      <div
        className="mb-4 pt-1"
        style={{ width: "48%", fontWeight: "500", fontSize: "20px" }}
      >
        {selectedJobData?.job?.seq_name}
      </div>
      <div className="mb-4 mt-2" style={{ width: "80%" }}>
        <div className="d-flex mb-3" style={{ width: "80%" }}>
          <div className="d-flex" style={{ color: "Gray",width:"20%" }}>Iteration 01</div>
          {(initialResult && [PENDING, INPROGRESS].includes(initialResult.status)) &&
            <>
              <div style={{ color: "orange"}}>{initialResult?.status}</div>
              <div className="ml-1 " style={{ color: "orange"}} ><CircularProgress
                        size={16}
                        color="inherit"
                      /></div>
            </>
          }
          {(initialResult && [HASERROR].includes(initialResult.status)) &&
            <>
              <div style={{ color: "red"}}>{initialResult?.error_type}</div>
              <div  className="ml-3" style={{ color: "red" }}>{initialResult?.error_message}</div>
            </>
          }
          {(initialResult && [COMPLETED].includes(initialResult.status)) &&
            <>
              <div className="clickable" onClick={()=>handleInitialResultView()} style={{ color: "Green"}}>Report</div>
              <div className="clickable ml-3" onClick={()=>handleInitialResultDownload()} style={{ color: "Green" }}>Suggestions Download</div>
            </> 
          }
        </div>
        {iterations && iterations.sort(compare).map((el, index) => 
          <div className="d-flex mb-3" key={el.id} style={{ width: "80%" }}>
            <div style={{ color: "Gray",width:"20%" }}>Iteration {pad(index+2)}</div>
            {el?.status === COMPLETED && <div className="clickable" onClick={() => handleIterationResultDownload(el.input_id, el.id)} style={{ color: "Green" }}>Suggestions Download</div>}
            {([PENDING, INPROGRESS].includes(el?.status)) &&
              <>
                <div style={{ color: "orange"}}>{el?.status}</div>
                <div className="ml-1 " style={{ color: "orange"}} ><CircularProgress
                          size={16}
                          color="inherit"
                /></div>
              </>
            }
            {([HASERROR].includes(el?.status)) &&
              <>
                <div style={{ color: "red"}}>{el?.error_type} : </div>
                <div  className="ml-3" style={{ color: "red" }}>{el?.error_message}</div>
              </>
            }
          </div>
        )}
        {([COMPLETED, HASERROR].includes(selectedJobData?.job?.status) && (initialResult && [COMPLETED].includes(initialResult.status))) &&
          <div className="d-flex mb-3 " style={{ width: "80%" }}>
            <div style={{ color: "Gray",width:"20%" }}>New Iteration</div>
            <div className="clickable" onClick={() => history.push(`/jobs/create-generators`)} style={{ color: "Green" }}>Get Suggestions</div>
          </div>
        }
      </div>
      {([COMPLETED, HASERROR].includes(selectedJobData?.job?.status) && (initialResult && [COMPLETED].includes(initialResult.status))) &&
        <>
          <div
            className="mb-4 pt-1"
            style={{ width: "48%", fontWeight: "500", fontSize: "20px" }}
          >
            Upload updated measurement data
          </div>
          <div
            className="w-100  green-border"
            style={{ height: "100%", width: "98%" }}
          >
            <div>
              <p className="text-semi-medium text-grey pl-3 pt-1">
                Supports : Excel, CSV
              </p>
            </div>

            <div {...getRootProps()} className="w-100 d-center" style={{ height: "30%" }}>
              <div
                className="w-100 bg-dark m-3 upload-back d-center"
                style={{ height: "226px" }}
              >
                <div className="d-flex justify-between">
                  <input
                    {...getInputProps()}
                  />
                  <label
                    className="btn btn-outlined-success d-center clickable"
                    htmlFor="selectFile"
                  >
                    <CloudUploadIcon className="mr-1" /> {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag and drop some files here, or click to select files</p>
              }
                  </label>
                </div>
              </div>
            </div>

            {csvFile && (
              <div className="p-3 relative">
                <div className="shadow-sm w-100">
                  <div className="p-1 d-flex">
                    <div className="d-center mr-2">
                      <img src={file} />
                    </div>
                    <div className="">
                      <p className="m-0">{csvFile.name}</p>
                      <p
                        className={`text-small ${handleTextColor()} text-semi-bold m-0`}
                      >
                        {handleStatusMessage()}
                      </p>
                    </div>
                  </div>
                  <Box
                    className="pl-1 pr-1 pb-1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress variant="buffer" value={progress} />
                    </Box>
                    {status ===  "Jobs/COMPLETED" && (
                      <>
                        <Box
                          hidden={status ===  "Jobs/COMPLETED"}
                          className="absolute progress-count"
                          sx={{ minWidth: 35 }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                          >{`${progress}%`}</Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </div>
              </div>
            )}
          </div>    
        </>
      }
    </div>
    
  );
};

export default SingleJobSummary;
