export const GET_PACKAGE_DATA = 'CREDIT/GET_PACKAGE_DATA';
export const GET_PACKAGE_DATA_SUCCESS = 'CREDIT/GET_PACKAGE_DATA_SUCCESS';
export const GET_PACKAGE_DATA_ERROR = 'CREDIT/GET_PACKAGE_DATA_ERROR';

export const SET_PACKAGE_DETAILS = 'CREDIT/SET_PACKAGE_DETAILS';

export const ADD_TO_CART = 'CREDIT/ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'CREDIT/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'CREDIT/ADD_TO_CART_ERROR';

export const GET_CART = 'CREDIT/GET_CART';
export const GET_CART_SUCCESS = 'CREDIT/GET_CART_SUCCESS';
export const GET_CART_ERROR = 'CREDIT/GET_CART_ERROR';

export const UPDATE_CART = 'CREDIT/UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'CREDIT/UPDATE_CART_SUCCESS';
export const UPDATE_CART_ERROR = 'CREDIT/UPDATE_CART_ERROR';

export const DELETE_CART = 'CREDIT/DELETE_CART';
export const DELETE_CART_SUCCESS = 'CREDIT/DELETE_CART_SUCCESS';
export const DELETE_CART_ERROR = 'CREDIT/DELETE_CART_ERROR';

export const CREATE_PAYMENT = 'CREDIT/CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'CREDIT/CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'CREDIT/CREATE_PAYMENT_ERROR';

export const GET_BALANCE = 'CREDIT/GET_BALANCE';
export const GET_BALANCE_SUCCESS = 'CREDIT/GET_BALANCE_SUCCESS';
export const GET_BALANCE_ERROR = 'CREDIT/GET_BALANCE_ERROR';